import { useState } from "react";
import CONFIG from "../../config";

const useStatisticsData = () => {
  const [statisticsData, setStatisticsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchStatisticsData = async (username, processFetchedData) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/criteria?username=${username}`);
      const data = await response.json();

      if (Array.isArray(data)) {
        console.log(data);
        setStatisticsData(data);
        const processedData = processFetchedData(data);
        setFilteredData(processedData);
      } else {
        console.error("Failed to fetch statistics data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching statistics data", error);
    }
  };

  const processFetchedData = (data) => {
    const processedData = [];
    const deviationColors = {
      "High Deviation": "#E0301E",
      "Medium Deviation": "#FFB600",
      "Low Deviation": "#7D7D7D",
      "Within SLA": "#FD6412"
    };

    data.forEach(item => {
      if (item.Service_Category) {
        const { Parent_Criteria: parentCriteria, Category: category, Sub_Category: subcategory, Service_Category: serviceCategory, Penalty_Pcnt: penaltyPcnt, Actual_Service_Level_percent: actualSLA, Deviation: deviation, SLA_type: slatype } = item;

        processedData.push({
          parentCriteria,
          category,
          subcategory,
          serviceCategory,
          penaltyPcnt,
          actualSLA,
          deviation,
          label: `${parentCriteria} - ${category} - ${subcategory}`, // Label for hover
          color: deviationColors[deviation], // Color based on deviation  
          slatype
        });
      }
    });

    return processedData;
  };

  return { statisticsData, filteredData, fetchStatisticsData, processFetchedData };
};

export default useStatisticsData;

import { useState, useEffect } from "react";
import CONFIG from "../../config"; // Ensure this path is correct

const useTrendData = () => {
  const [trendData, setTrendData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTrendData = async (username) => {
    setLoading(true);
    setError(null);
    
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
    const year = currentDate.getFullYear();

    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/trend-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, month, year }),
      });

      if (!response.ok) {
        if (response.status === 404) {
          console.error(`Data for ${month}-${year} not found (404).`);
          setTrendData([]); // Set empty data if not found
        } else {
          throw new Error('Network response was not ok');
        }
      } else {
        const data = await response.json();
        if (data) {
          setTrendData(data.reverse());// Store the data with month and year
        } else {
          console.error(`No data available for ${month}-${year}.`);
          setTrendData([]); // Set empty data if no data available
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { trendData, fetchTrendData, loading, error };
};

export default useTrendData;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActualSLAChart from "./ActualSLAChart";
import TrendChart from "./TrendChart";
import PenaltyPcntChart from "./PenaltyPcntChart";
import useStatisticsData from "./useStatisticsData";
import criteriaData from "./criteriaData";
import useTrendData from "./useTrendData";
import { BarChartIcon, LineChartIcon } from "./ChartIcons"; 
import "./Statistics.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import downarrow from "../../assets/downarrow.png";

const Statistics = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showCriteriaOptions, setShowCriteriaOptions] = useState(false);
  const [chartType, setChartType] = useState('line'); // Set default chart type to 'line'
  const [filteredData, setFilteredData] = useState([]);
  const [filteredTrendData, setFilteredTrendData] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null); // Manage which dropdown is open

  const { trendData, fetchTrendData } = useTrendData();
  const { statisticsData, fetchStatisticsData, processFetchedData } = useStatisticsData();
  const { criteriaList, selectedCriteria, fetchCriteriaData, handleCriteriaSelection, handleSelectAll } = criteriaData();

  const exportDropdownRef = useRef(null);
  const filterDropdownRef = useRef(null);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    const storedUsername = localStorage.getItem("username");

    if (loggedInUser === "true" && storedUsername) {
      setUsername(storedUsername);
      fetchCriteriaData(storedUsername);
      fetchStatisticsData(storedUsername);
      fetchTrendData(storedUsername);
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    if (statisticsData.length > 0 && selectedCriteria.length > 0) {
      const filtered = statisticsData.filter(item => 
        selectedCriteria.includes(item.Parent_Criteria)
      );
      const processedData = processFetchedData(filtered);
      setFilteredData(processedData);
    } else {
      setFilteredData([]);
    }
  }, [selectedCriteria, statisticsData]);

  useEffect(() => {
    if (trendData.length > 0 && selectedCriteria.length > 0) {
      const filteredTrendData = trendData.map(month => {
        return {
          ...month,
          data: month.data.filter(item => 
            selectedCriteria.includes(item.Parent_criteria)
          )
        };
      });

      setFilteredTrendData(filteredTrendData);
    } else {
      setFilteredTrendData([]);
    }
  }, [selectedCriteria, trendData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        exportDropdownRef.current &&
        !exportDropdownRef.current.contains(event.target) &&
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setShowExportOptions(false);
        setShowCriteriaOptions(false);
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const exportToImage = (format) => {
    const chartContainers = document.querySelectorAll('.chart-container');
    const canvasPromises = Array.from(chartContainers).map(container => 
      html2canvas(container)
    );

    Promise.all(canvasPromises).then(canvases => {
      const combinedCanvas = document.createElement('canvas');
      const context = combinedCanvas.getContext('2d');

      const totalHeight = canvases.reduce((sum, canvas) => sum + canvas.height, 0);
      combinedCanvas.width = Math.max(...canvases.map(c => c.width));
      combinedCanvas.height = totalHeight;

      let currentY = 0;
      canvases.forEach(canvas => {
        context.drawImage(canvas, 0, currentY);
        currentY += canvas.height;
      });

      const imgData = combinedCanvas.toDataURL(`image/${format}`);
      const link = document.createElement('a');
      link.href = imgData;
      link.download = `combined_charts.${format}`;
      link.click();
    });
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const chartContainers = document.querySelectorAll('.chart-container');
    const canvasPromises = Array.from(chartContainers).map(container => 
      html2canvas(container, { scale: 2 }) // Increase scale for better quality
    );

    Promise.all(canvasPromises).then(canvases => {
      const imgWidth = 180; // Width of the image in PDF (in mm)
      const pageHeight = doc.internal.pageSize.height; // Page height
      let heightLeft = 0; // Remaining height on the current page
      let position = 10; // Initial position on the page

      canvases.forEach((canvas, index) => {
        const imgData = canvas.toDataURL('image/jpeg');
        const imgHeight = canvas.height * imgWidth / canvas.width; // Calculate height based on width
        heightLeft = pageHeight - position; // Update remaining height on the current page

        if (imgHeight > heightLeft) {
          // Add a new page if the image doesn't fit
          doc.addPage();
          position = 10; // Reset position for the new page
        }

        doc.addImage(imgData, 'JPEG', 10, position, imgWidth, imgHeight);
        position += imgHeight; // Update position for the next chart

        // Add a new page if needed after adding the last chart
        if (index === canvases.length - 1 && position > heightLeft) {
          doc.addPage();
        }
      });

      doc.save('combined_charts.pdf');
    });
  };

  const handleCriteriaSelectionWithClose = (criteriaName) => {
    handleCriteriaSelection(criteriaName);
    // Do not close the dropdown here; wait for outside click
  };

  const handleSelectAllWithClose = () => {
    handleSelectAll();
    // Do not close the dropdown here; wait for outside click
  };

  const toggleDropdown = (dropdownName) => {
    if (activeDropdown === dropdownName) {
      // Close the dropdown if it's already open
      setActiveDropdown(null);
      setShowExportOptions(false);
      setShowCriteriaOptions(false);
    } else {
      // Close any open dropdowns and open the selected one
      setActiveDropdown(dropdownName);
      if (dropdownName === 'export') {
        setShowExportOptions(true);
        setShowCriteriaOptions(false); // Close the criteria dropdown
      } else if (dropdownName === 'criteria') {
        setShowCriteriaOptions(true);
        setShowExportOptions(false); // Close the export dropdown
      }
    }
  };

  return (
    <div>
      <div className="rightPane">
        <div className="rightPane-header">Statistics</div>
        <div className="options-container">
          <div className="chart-type-selector">
            <div
              className={`chart-icon ${chartType === 'line' ? 'active' : ''}`}
              onClick={() => setChartType('line')}
              title="Line Chart"
            >
              <LineChartIcon />
            </div>
            <div
              className={`chart-icon ${chartType === 'bar' ? 'active' : ''}`}
              onClick={() => setChartType('bar')}
              title="Bar Chart"
            >
              <BarChartIcon />
            </div>
          </div>
          <div className="dropdown-container">
            <div className="dropdown" ref={exportDropdownRef}>
              <div
                className="dropdown-header"
                onClick={() => {
                  toggleDropdown('export');
                }}
              >
                Export Options
                <img
                  src={downarrow}
                  alt="Arrow"
                  className={`arrow-icon ${showExportOptions ? "down-arrow" : "down-arrow"}`}
                />
              </div>
              {showExportOptions && (
                <div className="dropdown-content">
                  <div onClick={() => exportToImage('jpg')}>Export as Image (JPG)</div>
                  <div onClick={() => exportToImage('png')}>Export as Image (PNG)</div>
                  <div onClick={exportToPDF}>Export as PDF</div>
                </div>
              )}
            </div>

            <div className="dropdown" ref={filterDropdownRef}>
              <div
                className="dropdown-header"
                onClick={() => {
                  toggleDropdown('criteria');
                }}
              >
                Filter
                <img
                  src={downarrow}
                  alt="Arrow"
                  className={`arrow-icon ${showCriteriaOptions ? "down-arrow" : "down-arrow"}`}
                />
              </div>
              {showCriteriaOptions && (
                <div className="dropdown-content">
                  <div>
                    <input
                      type="checkbox"
                      id="select-all"
                      checked={selectedCriteria.length === criteriaList.length}
                      onChange={handleSelectAllWithClose}
                    />
                    <label htmlFor="select-all">
                      {selectedCriteria.length === criteriaList.length ? 'Clear All' : 'Select All'}
                    </label>
                  </div>
                  {criteriaList.map((criteria) => (
                    <div key={criteria.id}>
                      <input
                        type="checkbox"
                        id={`checkbox-${criteria.id}`}
                        checked={selectedCriteria.includes(criteria.criteria_name)}
                        onChange={() => handleCriteriaSelectionWithClose(criteria.criteria_name)}
                      />
                      <label htmlFor={`checkbox-${criteria.id}`}>{criteria.criteria_name}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>       
        <div className="chart-container trend-chart-container">
          <TrendChart trendData={filteredTrendData} chartType={chartType} />
        </div>
        <div className="chart-container">
          <ActualSLAChart filteredData={filteredData} chartType={chartType} />
        </div>
        <div className="chart-container">
          <PenaltyPcntChart filteredData={filteredData} chartType={chartType} />
        </div>
      </div>
    </div>
  );
};

export default Statistics;

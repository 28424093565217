import { useEffect, useState, useMemo,useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // For tables in PDF
import downarrow from "../../assets/downarrow.png";
import "./History.css";
import CONFIG from "../../config"; // Import the config file
import Confirmationmodal from "../Criteria/Confirmationmodal";

const Report = () => {
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState(null); // New state to manage which dropdown is open
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showCriteriaOptions, setShowCriteriaOptions] = useState(false);
  const [showMonthOptions, setShowMonthOptions] = useState(false);
  const [showYearOptions, setShowYearOptions] = useState(false);
  const [showQuarterlyOptions, setShowQuarterlyOptions] = useState(false);
  const [showHalfyearlyOptions, setShowHalfyearlyOptions] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const initialYear = new Date().getFullYear(); 
  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [selectedHalfyear, setSelectedHalfyear] = useState(null);
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const [criteriaList, setCriteriaList] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const exportOptionsRef = useRef(null);
  const yearOptionsRef = useRef(null);
  const monthOptionsRef = useRef(null);
  const quarterlyOptionsRef = useRef(null);
  const halfyearlyOptionsRef = useRef(null);
  const criteriaOptionsRef = useRef(null);

  // Memoize months and years
  const months = useMemo(() => [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ], []);

  const currentYear = new Date().getFullYear();
  const years = useMemo(() => Array.from({ length: 10 }, (_, i) => currentYear + i), [currentYear]);

  // Get the current month and year
  const currentMonthIndex = new Date().getMonth(); // 0-based index
  const currentMonth = months[currentMonthIndex];
  const currentYearValue = currentYear;

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    const storedUsername = localStorage.getItem("username");
    if (loggedInUser === "true" && storedUsername) {
      fetchCriteriaData(storedUsername);
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const loggedInUser = localStorage.getItem("username");
      if (loggedInUser) {
        fetchReportData(loggedInUser, selectedCriteria, selectedMonth, selectedYear);
      }
    }
  }, [selectedMonth, selectedYear, selectedCriteria]);

  
  useEffect(() => {
    const processFilteredData = async () => {
      
      const dataToProcess = selectedCriteria
        ? reportData.filter(item =>
            item.Parent_Criteria === selectedCriteria || 
            (!item.isParent && item.Parent_Criteria === selectedCriteria)
          )
        : reportData;
        

      console.log("Data to process:", dataToProcess);
      const processedFilteredData = [];
      let currentParent = null;
      let currentCategory = null;
      let currentSubCategory = null;
      let serialNumber = 1;

      for (const item of dataToProcess) {
        if (item.Parent_Criteria !== currentParent) {
          currentParent = item.Parent_Criteria;
          processedFilteredData.push({
            Service_Category: currentParent,
            isParent: true,
            serialNumber: null,
            Deviation: item.Project_Deviation,
            Penalty_Pcnt: item.Avg_Penalty
          });
          currentCategory = null;
          currentSubCategory = null;
          serialNumber = 1;
        }

        if (item.Category && item.Category !== currentCategory) {
          currentCategory = item.Category;
          processedFilteredData.push({
            Service_Category: currentCategory,
            isCategory: true,
            serialNumber: null,
            Deviation: item.Deviation_category || "N/A",
            Penalty_Pcnt: item.Penalty_Pcnt_category || "N/A"
          });
          currentSubCategory = null;
        }

        if (item.Sub_Category && item.Sub_Category !== currentSubCategory) {
          currentSubCategory = item.Sub_Category;
          processedFilteredData.push({
            Service_Category: currentSubCategory,
            isSubCategory: true,
            serialNumber: null,
            Deviation: item.Deviation_subcategory || "N/A",
            Penalty_Pcnt: item.Penalty_Pcnt_subcategory || "N/A"
          });
        }

        processedFilteredData.push({
          ...item,
          serialNumber: item.isParent || item.isCategory || item.isSubCategory ? null : serialNumber++
        });
      }
      //console.log("Processed Filtered Data:", processedFilteredData); // Check data here
      setFilteredData(processedFilteredData);
    };
    
    processFilteredData();
  }, [selectedCriteria]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        exportOptionsRef.current && !exportOptionsRef.current.contains(event.target) &&
        yearOptionsRef.current && !yearOptionsRef.current.contains(event.target) &&
        monthOptionsRef.current && !monthOptionsRef.current.contains(event.target) &&
        quarterlyOptionsRef.current && !quarterlyOptionsRef.current.contains(event.target) &&
        halfyearlyOptionsRef.current && !halfyearlyOptionsRef.current.contains(event.target) &&
        criteriaOptionsRef.current && !criteriaOptionsRef.current.contains(event.target)
      ) {
        // Close all dropdowns if the click is outside of any dropdown
        setShowExportOptions(false);
        setShowYearOptions(false);
        setShowMonthOptions(false);
        setShowQuarterlyOptions(false);
        setShowHalfyearlyOptions(false);
        setShowCriteriaOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    //document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      //document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fetchCriteriaData = async (username) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/get_criteria?username=${username}`);
      const data = await response.json();
      if (data.success) {
        setCriteriaList(data.criteria_data);
      } else {
        console.error("Failed to fetch criteria data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching criteria data", error);
    }
  };

  const fetchReportData = async (username, parentCriteria = null, month = null, year = null, attempt = 0, maxAttempts = 3) => {
    const monthParams = month ? month : selectedMonths.join(',');
    
    try {
      const queryParams = new URLSearchParams({
        username,
        ...(parentCriteria && { parent_criteria: parentCriteria }),
        ...(monthParams && { month: monthParams }),
        ...(year && { year }) // Include year if provided
      }).toString();
  
      const url = `${CONFIG.API_BASE_URL}api/history?${queryParams}`;
      const response = await fetch(url);
      const data = await response.json();
  
      if (Array.isArray(data) && data.length > 0) {
        const processedData = [];
        let currentCategory = null;
        let currentSubCategory = null;
        let currentParent = null;
  
        data.forEach(item => {
          if (item.Parent_Criteria !== currentParent) {
            currentParent = item.Parent_Criteria;
            processedData.push({
              Service_Category: currentParent,
              isParent: true,
              serialNumber: null,
              Deviation: item.Project_Deviation,
              Penalty_Pcnt: item.Avg_Penalty
            });
            currentCategory = null;
            currentSubCategory = null;
          }
  
          if (item.Category !== currentCategory) {
            currentCategory = item.Category;
            processedData.push({
              Service_Category: currentCategory,
              isCategory: true,
              serialNumber: null,
              Deviation: item.Deviation_category || " ",
              Penalty_Pcnt: item.Penalty_Pcnt_category || " "
            });
            currentSubCategory = null;
          }
  
          if (item.Sub_Category !== currentSubCategory) {
            currentSubCategory = item.Sub_Category;
            processedData.push({
              Service_Category: currentSubCategory,
              isSubCategory: true,
              serialNumber: null,
              Deviation: item.Deviation_subcategory || " ",
              Penalty_Pcnt: item.Penalty_Pcnt_subcategory || " "
            });
          }
  
          processedData.push({
            ...item,
            isParent: false,
            isCategory: false,
            isSubCategory: false,
            serialNumber: item.serialNumber || null
          });
        });
  
        setReportData(processedData);
        setFilteredData(processedData);
        setNoDataFound(false); // Reset the no data found flag
        setAttempts(0); // Reset the attempts count
  
        return true; // Indicate that data was found
      } else {
        if (attempt < maxAttempts - 1) { // Change condition based on maxAttempts
          setAttempts(prev => prev + 1);
          await fetchReportData(username, parentCriteria, monthParams, year, attempt + 1, maxAttempts); // Retry with incremented attempt
        } else {
          setNoDataFound(true);
          setReportData([]);
          setFilteredData([]);
          return false; // Indicate that no data was found
         
        }
      }
    } catch (error) {
      if (attempt < maxAttempts - 1) { // Change condition based on maxAttempts
        setAttempts(prev => prev + 1);
        await fetchReportData(username, parentCriteria, monthParams, year, attempt + 1, maxAttempts); // Retry with incremented attempt
      } else {
        setNoDataFound(true);
        setReportData([]);
        setFilteredData([]);
        return false; // Indicate that no data was found
        
      }
    }
  };
 
  const exportToExcel = () => {
    const ws = XLSX.utils.table_to_sheet(document.querySelector('.report-table'));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '.report-table' });
    doc.save('report.pdf');
  };

  const handleCriteriaSelection = (criteria) => {
    setSelectedCriteria(criteria);
    setShowCriteriaOptions(false);
    //setShowDropdown(null); // Close all dropdowns
  };

  const handleTimeOptionClick = (option) => {
    switch (option) {
      case "Month":
        setShowMonthOptions(!showMonthOptions);
        break;
      case "Year":
        setShowYearOptions(!showYearOptions);
        break;
      case "Quarterly":
        setShowQuarterlyOptions(!showQuarterlyOptions);
        break;
      case "Halfyearly":
        setShowHalfyearlyOptions(!showHalfyearlyOptions);
        break;
      default:
        break;
    }
  };
  
  const handleMonthSelection = (month) => {
    setSelectedMonth(month);
    setSelectedQuarter(null);
    setSelectedHalfyear(null);
    setSelectedMonths([month]);
    setShowMonthOptions(false);
    setShowQuarterlyOptions(false);
    setShowHalfyearlyOptions(false);
    //setShowDropdown(null); // Close all dropdowns
    // If a year is selected, initiate data fetching for the given year
    if (selectedYear) {
        fetchReportData(localStorage.getItem("username"), selectedCriteria, month, selectedYear)
            .then(dataFound => {
                if (!dataFound) {
                    setConfirmationMessage('No Data found for the selected month.');
                    setIsModalOpen(true); // Show modal if no data found
                }
            })
            .catch(error => {
                // Handle any errors from the data fetching
                console.error("Error fetching report data:", error);
                setConfirmationMessage('An error occurred while fetching the data.');
                setIsModalOpen(true);
            });
    } else {
        alert("Please select a year first.");
    }
};

  
  const handleYearSelection = (year) => {
    setSelectedYear(year);
    // If a month is selected, initiate data fetching
    if (selectedMonth) {
      fetchReportData(localStorage.getItem("username"), selectedCriteria, selectedMonth, year);
    }
    setShowYearOptions(false);
    setShowMonthOptions(false);
    setShowQuarterlyOptions(false);
    setShowHalfyearlyOptions(false);
    //setShowDropdown(null); // Close all dropdowns
  
  };
    
  const handleQuarterlySelection = (quarter) => {
    // Define mapping from quarters to months
    const quarterToMonths = {
      "Q1-April to June": ["June", "May", "April"],
      "Q2-July to September": ["September", "August", "July"],
      "Q3-October to December": ["December", "November", "October"],
      "Q4-January to March": ["March", "February", "January"]
    };
    // Get the months for the selected quarter
    const months = quarterToMonths[quarter] || [];
  
    // Set selected states
    setSelectedQuarter(quarter);
    setSelectedMonths(months);
    setSelectedMonth(null); // Reset selectedMonth
    setSelectedHalfyear(null); // Reset selectedHalfyear
    //setShowDropdown(null); // Close all dropdowns
  
    // If a year is selected, initiate data fetching for the given year
    if (selectedYear) {
      fetchQuarterlyDataForMonths(months, selectedYear);
    } else {
      alert("Please select a year first.");
    }
  
    // Hide other options
    setShowQuarterlyOptions(false);
    setShowMonthOptions(false);
    setShowHalfyearlyOptions(false);
  };
  
  const fetchQuarterlyDataForMonths = async (months, year) => {
    const loggedInUser = localStorage.getItem("username");
    if (!loggedInUser) return;

    let dataFound = false;
    let message = '';
    let updatedYear = year;
    // Check if the previously selected quarter was Q4 and the new selection is Q1-Q3
    if (selectedQuarter && selectedQuarter.startsWith('Q4') && months.some(month => ["April", "May", "June"].includes(month))) {
        // Transitioning from Q4 to Q1-Q3
        //alert(months);
        //alert("1");
        year -= 1;
        updatedYear -= 1;
        setSelectedYear(prevYear => prevYear - 1); // Decrement year in state    
    }
    if (selectedQuarter && selectedQuarter.startsWith('Q4') && months.some(month => ["July", "August", "September"].includes(month))) {
      // Transitioning from Q4 to Q1-Q3
      //alert(months);
      //alert("1");
      year -= 1;
      updatedYear -= 1;
      setSelectedYear(prevYear => prevYear - 1); // Decrement year in state
    }
    if (selectedQuarter && selectedQuarter.startsWith('Q4') && months.some(month => ["October", "November", "December"].includes(month))) {
      // Transitioning from Q4 to Q1-Q3
      //alert(months);
      //alert("1");
      year -= 1;
      updatedYear -= 1;
      setSelectedYear(prevYear => prevYear - 1); // Decrement year in state
    }
    for (const month of months) {
      //const quarter4 = "Q4-January to March";
      //alert(quarter4);
      if (month === "March"){
        let updatedYear = selectedYear;
        year += 1;
        setSelectedYear(prevYear => prevYear + 1); // Update state with new year
        updatedYear += 1; // Increment the year in local variable
        //alert(month);
        //alert(updatedYear);
      }
        dataFound = await fetchReportData(loggedInUser, selectedCriteria, month, year, 0, 3); // 3 attempts for quarterly
        if (dataFound) {
          //alert(`Data found for ${month}`); // Alert with month name
          setSelectedMonth(month); // Optionally, set selected month if needed
          break; // Stop checking further months if data is found
      }
    }

    if (!dataFound) {
        message = 'No data found for the selected months.';
        setNoDataFound(true);
        setConfirmationMessage(message);
        setIsModalOpen(true); // Set the confirmation message
    }
};
  const handleHalfyearlySelection = (Halfyear) => {
    // Define mapping from halfyears to months
    const halfyearToMonths = {
      "HY1-April to September": ["September", "August", "July", "June", "May", "April"],
      "HY2-October to March": ["March", "February", "January", "December", "November", "October"]
    };
    // Assuming `Halfyear` contains the selected half-year key
    const halfyear = "HY2-October to March"; // Example selected half-year

    // Get the months for the selected halfyear
    const months = halfyearToMonths[Halfyear] || [];
    /////////////////////////////////////
    // Extract specific months into separate variables
      const monthsRequiringYearIncrement = ["March", "January", "February"];
      let updatedYear = selectedYear; // Initialize with current selected year

      // Check if the selected half-year is "HY2-October to March"
        if (halfyear === "HY2-October to March") {
          //alert("inside");
         const shouldIncrementYear = months.some(month => monthsRequiringYearIncrement.includes(month));
        }
        //alert(halfyear);
    /////////////////////////////////////////////
    // Set selected states
    // Update the year state
    setSelectedYear(updatedYear);

    setSelectedHalfyear(Halfyear);
    setSelectedMonths(months);
    setSelectedMonth(null); // Reset selectedMonth
    setSelectedQuarter(null); // Reset selectedQuarter
    //setShowDropdown(null); // Close all dropdowns
  
    // If a year is selected, initiate data fetching for the given year
    if (updatedYear) {
      fetchHalfyearlyDataForMonths(months, updatedYear);
      //alert(updatedYear);
    } else {
      alert("Please select a year first.");
    }

    setShowHalfyearlyOptions(false);
    setShowMonthOptions(false);
    setShowQuarterlyOptions(false);
  };
  const fetchHalfyearlyDataForMonths = async (months, year) => {
    const loggedInUser = localStorage.getItem("username");
    if (!loggedInUser) return;
    
    let dataFound = false;
    let message = '';
    let updatedYear = year;
    // Check if the previously selected quarter was Q4 and the new selection is Q1-Q3
    if (selectedHalfyear && selectedHalfyear.startsWith('HY2') && months.some(month => ["April", "May", "June", "July", "August", "September"].includes(month))) {
        year -= 1;
        updatedYear -= 1;
        setSelectedYear(prevYear => prevYear - 1); // Decrement year in state    
    }
    for (const month of months) {
        if(month ===  "March"){
        let updatedYear = selectedYear;
        year += 1;
        setSelectedYear(prevYear => prevYear + 1); // Update state with new year
        updatedYear += 1; // Increment the year in local variable
        //alert("1");
    }
      if(month === "December"){
        let updatedYear = selectedYear;
        year -= 1;
        setSelectedYear(prevYear => prevYear - 1); // Update state with new year
        updatedYear -= 1; // Decrement the year in local variable
        //alert("3");
      }
      
      //alert(halfyear);
      dataFound = await fetchReportData(loggedInUser, selectedCriteria, month, year, 0, 6); // 6 attempts for half-yearly
      if (dataFound) {
          setSelectedMonth(month); // Optionally, set selected month if needed
          break; // Stop checking further months if data is found
      }
  }
  
    if (!dataFound) {
      message = 'No data found for the selected months.';
      setNoDataFound(true);
      setConfirmationMessage(message);
      setIsModalOpen(true); // Set the confirmation message
  }
  };
 
  
  const handleClearSelection = (option) => {
    switch (option) {
      case "Year":
        setSelectedYear(null);
        setShowYearOptions(false);
        break;
      case "Month":
        setSelectedMonth(null);
        setShowMonthOptions(false);
        break;
      case "Quarterly":
        setSelectedQuarter(null);
        setShowQuarterlyOptions(false);
        break;
      case "Halfyearly":
        setSelectedHalfyear(null);
        setShowHalfyearlyOptions(false);
        break;
      case "Criteria":
        setSelectedCriteria("");
        setShowCriteriaOptions(false);
        break;
      default:
        break;
    }
    //setShowDropdown(null); // Close all dropdowns
  };

  return (
    <div className="report-container">
      <div className="leftPane">
        {/* Add your left pane content here */}
      </div>
      <div className="rightPane">
        <div className="rightPane-header">HISTORY</div>
        <div className="dropdown-container">
        <div className="dropdown" ref={exportOptionsRef}>
          <div
            className="dropdown-header"
            onClick={() => {
              setShowExportOptions(!showExportOptions)
              setShowYearOptions(false);
              setShowMonthOptions(false);
              setShowQuarterlyOptions(false);
              setShowHalfyearlyOptions(false);
              setShowCriteriaOptions(false);
            }}
          >
            Export Options
            <img
              src={downarrow}
              alt="Arrow"
              className={`arrow-icon ${showExportOptions ? "down-arrow" : ""}`}
            />
          </div>
          {showExportOptions && (
            <div className="dropdown-content">
              <div onClick={exportToExcel}>Excel</div>
              <div onClick={exportToPDF}>PDF</div>
            </div>
          )}
        </div>

        <div className="dropdown" ref={yearOptionsRef}>
          <div
            className="dropdown-header"
            onClick={() => 
              {
                handleTimeOptionClick("Year")
                setShowExportOptions(false);
                setShowMonthOptions(false);
                setShowQuarterlyOptions(false);
                setShowHalfyearlyOptions(false);
                setShowCriteriaOptions(false);
            }}
          >
            {selectedYear || "Year"}
            <img
              src={downarrow}
              alt="Arrow"
              className={`arrow-icon ${showYearOptions ? "down-arrow" : ""}`}
            />
          </div>
          {showYearOptions && (
            <div className="dropdown-content">
              <div onClick={() => handleClearSelection("Year")}>Select</div>
              {years.map((year, index) => (
                <div key={index} onClick={() => handleYearSelection(year)}>
                  {year}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="dropdown" ref={monthOptionsRef}>
          <div
            className="dropdown-header"
            onClick={() => {
              handleTimeOptionClick("Month")
              setShowExportOptions(false);
              setShowYearOptions(false);
              setShowQuarterlyOptions(false);
              setShowHalfyearlyOptions(false);
              setShowCriteriaOptions(false);
            }}
          >
            {selectedMonth || "Month"}
            <img
              src={downarrow}
              alt="Arrow"
              className={`arrow-icon ${showMonthOptions ? "down-arrow" : ""}`}
            />
          </div>
          {showMonthOptions && (
            <div className="dropdown-content">
              <div onClick={() => handleClearSelection("Month")}>Select</div>
              {months.map((month, index) => (
                <div key={index} onClick={() => handleMonthSelection(month)}>
                  {month}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="dropdown" ref={quarterlyOptionsRef}>
          <div
            className="dropdown-header"
            onClick={() => {
              handleTimeOptionClick("Quarterly")
              setShowExportOptions(false);
              setShowYearOptions(false);
              setShowMonthOptions(false);
              setShowHalfyearlyOptions(false);
              setShowCriteriaOptions(false);
            }}
          >
            {selectedQuarter || "Quarterly"}
            <img
              src={downarrow}
              alt="Arrow"
              className={`arrow-icon ${showQuarterlyOptions ? "down-arrow" : ""}`}
            />
          </div>
          {showQuarterlyOptions && (
            <div className="dropdown-content">
              <div onClick={() => handleClearSelection("Quarterly")}>Select</div>
              <div onClick={() => handleQuarterlySelection("Q1-April to June")}>Q1-Apr to Jun</div>
              <div onClick={() => handleQuarterlySelection("Q2-July to September")}>Q2-Jul to Sep</div>
              <div onClick={() => handleQuarterlySelection("Q3-October to December")}>Q3-Oct to Dec</div>
              <div onClick={() => handleQuarterlySelection("Q4-January to March")}>Q4-Jan to Mar</div>
            </div>
          )}
        </div>

        <div className="dropdown" ref={halfyearlyOptionsRef}>
          <div
            className="dropdown-header"
            onClick={() => {
              handleTimeOptionClick("Halfyearly")
              setShowExportOptions(false);
              setShowYearOptions(false);
              setShowMonthOptions(false);
              setShowQuarterlyOptions(false);
              setShowCriteriaOptions(false);
            }}
          >
            {selectedHalfyear || "Half Yearly"}
            <img
              src={downarrow}
              alt="Arrow"
              className={`arrow-icon ${showHalfyearlyOptions ? "down-arrow" : ""}`}
            />
          </div>
          {showHalfyearlyOptions && (
            <div className="dropdown-content">
              <div onClick={() => handleClearSelection("Halfyearly")}>Select</div>
              <div onClick={() => handleHalfyearlySelection("HY1-April to September")}>HY1-Apr to Sep</div>
              <div onClick={() => handleHalfyearlySelection("HY2-October to March")}>HY2-Oct to Mar</div>
            </div>
          )}
        </div>

        <div className="dropdown" ref={criteriaOptionsRef}>
          <div
            className="dropdown-header"
            onClick={() => {
              setShowCriteriaOptions(!showCriteriaOptions)
              setShowExportOptions(false);
              setShowYearOptions(false);
              setShowMonthOptions(false);
              setShowQuarterlyOptions(false);
              setShowHalfyearlyOptions(false);
            }}
          >
            {selectedCriteria || "Filter"}
            <img
              src={downarrow}
              alt="Arrow"
              className={`arrow-icon ${showCriteriaOptions ? "down-arrow" : ""}`}
            />
          </div>
          {showCriteriaOptions && (
            <div className="dropdown-content">
              <div onClick={() => handleClearSelection("Criteria")}>Select All</div>
              {criteriaList.map((criteria) => (
                <div key={criteria.id} onClick={() => handleCriteriaSelection(criteria.criteria_name)}>
                  {criteria.criteria_name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

        <div className="table-container">
          <table className="report-table">
            <thead>
              <tr>
                <th rowSpan="2">Sr No</th>
                <th rowSpan="2">Service category</th>
                <th rowSpan="2">SLA</th>
                <th rowSpan="2">Priority</th>
                <th colSpan="3">Service Level Deviation</th>
                <th rowSpan="2">Actual SLA</th>
                <th rowSpan="2">Deviation</th>
                <th rowSpan="2">Priority Score</th>
                <th rowSpan="2">Penalty %</th>
              </tr>
              <tr>
                <th>Low</th>
                <th>Medium</th>
                <th>High</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                item.isParent ? (
                  <tr key={index} className="parent-row">
                    <td colSpan="8">{item.Service_Category}</td>
                    <td>{item.Deviation}</td>
                    <td></td>
                    <td>{item.Penalty_Pcnt}</td>
                  </tr>
                ) : item.isCategory ? (
                  <tr key={index} className="category-row">
                    <td colSpan="8">{item.Service_Category}</td>
                    <td>{item.Deviation}</td>
                    <td></td>
                    <td>{item.Penalty_Pcnt}</td>
                  </tr>
                ) : item.isSubCategory ? (
                  <tr key={index} className="sub-category-row">
                    <td colSpan="8">{item.Service_Category}</td>
                    <td>{item.Deviation}</td>
                    <td></td>
                    <td>{item.Penalty_Pcnt}</td>
                  </tr>
                ) : (
                  <tr key={index} className="service-category-row">
                    <td>{item.serialNumber || ""}</td>
                    <td>{item.Service_Category}</td>
                    <td>{item.SLA_Pcnt}</td>
                    <td>{item.Priority}</td>
                    <td>{item.Service_Dev_Low}</td>
                    <td>{item.Service_Dev_Med}</td>
                    <td>{item.Service_Dev_High}</td>
                    <td>{item.Actual_Service_Level_percent}</td>
                    <td>{item.Deviation}</td>
                    <td>{item.Priority_Score !== null ? item.Priority_Score : "N/A"}</td>
                    <td>{item.Penalty_Pcnt}</td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Confirmationmodal
      isOpen={isModalOpen}
      message={confirmationMessage}
      isError={isError}
      onClose={() => setIsModalOpen(false)} // Close the modal when clicking the close button
      />
    </div>
  );
};

export default Report;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.dmodal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 16px;
    width: 380px;
    height: 130px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    text-align: center;
    color:black;
    
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.dmodal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.dmodal-buttons button {
    width: 40%;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
}

.dmodal-buttons button:first-child {
    background-color:  #c4c5c6; 
    color: black;
}

.dmodal-buttons button:last-child {
    background-color: #c4c5c6; 
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Criteria/DeletePopUp.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,wCAAwC;IACxC,8BAA8B;IAC9B,kBAAkB;IAClB,WAAW;;AAEf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;;AAEA;;IAEI,YAAY;IACZ,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,eAAe;IACf,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".dmodal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background-color: rgba(0, 0, 0, 0.75);\r\n    z-index: 1000;\r\n}\r\n\r\n.dmodal-content {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    background: white;\r\n    padding: 20px;\r\n    border-radius: 16px;\r\n    width: 380px;\r\n    height: 130px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    font-family: Arial, sans-serif;\r\n    text-align: center;\r\n    color:black;\r\n    \r\n}\r\n\r\n.close {\r\n    color: #aaa;\r\n    float: right;\r\n    font-size: 28px;\r\n    font-weight: bold;\r\n}\r\n\r\n.close:hover,\r\n.close:focus {\r\n    color: black;\r\n    text-decoration: none;\r\n    cursor: pointer;\r\n}\r\n\r\n.dmodal-buttons {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-top: 40px;\r\n}\r\n\r\n.dmodal-buttons button {\r\n    width: 40%;\r\n    padding: 10px;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    border: none;\r\n    border-radius: 8px;\r\n}\r\n\r\n.dmodal-buttons button:first-child {\r\n    background-color:  #c4c5c6; \r\n    color: black;\r\n}\r\n\r\n.dmodal-buttons button:last-child {\r\n    background-color: #c4c5c6; \r\n    color: black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Login from "./pages/Login/Login";
import UpdateLogin from "./pages/Login/updateLogin";
import Criteria from "./pages/Criteria/Criteria";
import ProjectMilestone from "./pages/Criteria/ProjectMilestone";
import BusinessAsUsual from "./pages/Criteria/BusinessAsUsual";
import EditPopUp from "./pages/Criteria/EditPopUp";
import DeletePopUp from "./pages/Criteria/DeletePopUp";
import AddCriteria from "./pages/Criteria/UpdateCriteriaPM";
import Report from "./pages/Report/Report";
import Statistics from "./pages/Statistics/Statistics";
import Summary from "./pages/Summary/Summary";
import History from "./pages/History/History";
import Layout from "./component/Layout";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="updateLogin" element={<UpdateLogin />} />
        
        <Route path="/" element={<Layout />}>
          <Route path="criteria" element={<Criteria />} />
          <Route path="criteria/projectmilestone" element={<ProjectMilestone />} />
          <Route path="criteria/businessasusual" element={<BusinessAsUsual />} />
          <Route path="criteria/addcriteria" element={<AddCriteria />} />
          <Route path="criteria/editpopup" element={<EditPopUp />} />
          <Route path="criteria/deletepopup" element={<DeletePopUp />} />
          <Route path="report" element={<Report />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="summary" element={<Summary />} />
          <Route path="history" element={<History />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import fronticon from "../../assets/fronticon.svg";
import backicon from "../../assets/backicon.svg";
import downarrow from "../../assets/downarrow.svg";
import "./BusinessAsUsual.css";
import CONFIG from '../../config';
import Confirmationmodal from "./Confirmationmodal";

const BusinessAsUsual = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openIndex, setOpenIndex] = useState(null);
  const [finalData, setFinalData] = useState([]);
  const [username, setUsername] = useState('');
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [criteriaList, setCriteriaList] = useState([]);
  const [businessAsUsual, setBusinessAsUsual] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [activeSubCategory, setActiveSubCategory] = useState(null);
  const criteriaId = location.state?.criteriaId || '';
  const criteriaName = location.state?.criteriaName || '';
  const tabContainerRef = useRef(null);
  const innerTabContainerRef = useRef(null);
  const innerBuTabContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
    //const [isExpanded, setIsExpanded] = useState(false);
  const [expandedStates, setExpandedStates] = useState({});
    //const toggleExpansion = () => {
        //setIsExpanded(!isExpanded);
    //};
    const toggleExpansion = (category, subCategory, serviceCategory) => {
      setExpandedStates(prevStates => ({
          ...prevStates,
          [`${category}-${subCategory}-${serviceCategory}`]: !prevStates[`${category}-${subCategory}-${serviceCategory}`]
      }));
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    const storedUsername = localStorage.getItem("username");
    if (loggedInUser === "true" && storedUsername) {
      setUsername(storedUsername);
      fetchCriteriaData(storedUsername);
      fetchCriteriaContent(storedUsername, criteriaId, criteriaName);
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate, criteriaId, criteriaName]);

  useEffect(() => {
    setBusinessAsUsual(
      finalData.filter(business =>
        business.category || business.sub_category || business.service_category || business.sla || business.SLA_type || business.priority ||
        business.deviationLow || business.deviationMed || business.deviationHigh || business.actualServiceLevel || business.remarks
      )
    );
  }, [finalData]);

  const fetchCriteriaData = async (username) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/get_criteria?username=${username}`);
      const data = await response.json();
      if (data.success) {
        setCriteriaList(data.criteria_data);
      } else {
        console.error("Failed to fetch criteria data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching criteria data:", error);
    }
  };

  const scrollContainer = (direction, containerRef) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const fetchCriteriaContent = async (username, criteriaId, criteriaName) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/criteria_content`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          criteria_id: criteriaId,
          criteriaName: criteriaName
        }),
      });

      const data = await response.json();
      if (data.data && data.data.length > 0) {
        const fetchedData = data.data.map(item => ({
          category: item.Category,
          sub_category: item.Sub_Category,
          service_category: item.Service_Category,
          sla: item.SLA_Pcnt,
          SLA_type: item.SLA_type,
          priority: item.Priority,
          deviationLow: item.Service_Dev_Low,
          deviationMed: item.Service_Dev_Med,
          deviationHigh: item.Service_Dev_High,
          actualServiceLevel: item.Actual_Service_Level_percent || '',
          remarks: item.remarks || ''  // Handle remarks
        }));

        const localStorageData = JSON.parse(localStorage.getItem('bau') ||[]);
        const normalizedLocalStorageData = localStorageData.map(item => ({
            id: item.id,
            row: item.row,
            category: item.category,
            sub_category: item.subCategory,
            service_category: item.serviceCategory,
            sla: item.sla,
            SLA_type: item.SLA_type,
            priority: item.priority,
            deviationLow: item.deviationLow,
            deviationMed: item.deviationMedium,
            deviationHigh: item.deviationHigh,
            actualServiceLevel: item.actualServiceLevel,
            remarks: item.remarks || ''  // Handle remarks
        }));

        const filteredLocalStorageData = normalizedLocalStorageData.filter(item => item.id === criteriaId);
        if ((Array.isArray(localStorageData) && localStorageData.length === 0))
        {
          setFinalData(fetchedData);
        }
        else{
          setFinalData(filteredLocalStorageData);
        }
      } else {
        console.log('No data found');
      }
    } catch (error) {
      console.error('Error fetching criteria content:', error);
    }
  };

  const handleToggleDiv = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
      setActiveSubCategory(null);
    } else {
      setOpenIndex(index);
      const firstSubCategory = Object.keys(groupedByCategory[Object.keys(groupedByCategory)[index]])[0];
      setActiveSubCategory(firstSubCategory);
    }
  };

  const handleSubCategoryClick = (subCategory) => {
    setActiveSubCategory(activeSubCategory === subCategory ? null : subCategory);
  };

  const handleCloseServiceModal = () => setIsServiceModalOpen(false);

  const handleUpdateServiceCategories = (updatedCategories) => {
    setBusinessAsUsual(updatedCategories);
  };

  const handleNavigateToProjectMilestones = (criteriaName, criteriaId) => {
    navigate("/criteria/projectmilestone", { state: { criteriaName, criteriaId } });
  };

  const handleSaveChanges = () => {
    const dataToSend = businessAsUsual.map(business => ({
      criteria_id: criteriaId,
      Parent_criteria: criteriaName,
      Category: business.category,
      sub_category: business.sub_category,
      service_category: business.service_category,
      sla_pct: business.sla,
      SLA_type: business.SLA_type,
      priority: business.priority,
      Service_Dev_Low: business.deviationLow,
      Service_Dev_med: business.deviationMed,
      Service_Dev_high: business.deviationHigh,
      actualServiceLevel: business.actualServiceLevel,
      remarks: business.remarks || '',  // Include remarks field
      username: username,
    }));

    fetch(`${CONFIG.API_BASE_URL}api/bau`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
    .then(response => {
      if (response.ok) {
        setConfirmationMessage('Criteria saved');
        setIsError(false);
        localStorage.removeItem(`bau`);
      } else {
        return response.text().then(text => { throw new Error(text); });
      }
    })
    .catch(error => {
      setConfirmationMessage(`Error: ${error.message}`);
      setIsError(true);
    })
    .finally(() => {
      setIsModalOpen(true);
    });
  };

  const handleActualServiceLevelChange = (category, subCategory, serviceCategory, newValue) => {
    // Remove any characters that are not digits or a decimal point

    let cleanedValue = newValue.replace(/[^0-9.]/g, '');

    // Handle multiple decimal points
    const parts = cleanedValue.split('.');
    if (parts.length > 2) {
        cleanedValue = `${parts[0]}.${parts.slice(1).join('')}`;
    }

    // Limit decimal places to two
    if (parts[1] && parts[1].length > 2) {
        cleanedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    // Update the state with the cleaned and formatted value
    setBusinessAsUsual(prevData =>
        prevData.map(item =>
            item.category === category &&
            item.sub_category === subCategory &&
            item.service_category === serviceCategory
                ? { ...item, actualServiceLevel: cleanedValue }
                : item
        )
    );
};

  const handleRemarksChange = (category, subCategory, serviceCategory, newValue) => {
    setBusinessAsUsual(prevData =>
      prevData.map(item =>
        item.category === category &&
        item.sub_category === subCategory &&
        item.service_category === serviceCategory
          ? { ...item, remarks: newValue }
          : item
      )
    );
  };

  const groupedByCategory = businessAsUsual.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = {};
    }
    if (!acc[item.category][item.sub_category]) {
      acc[item.category][item.sub_category] = [];
    }
    acc[item.category][item.sub_category].push(item);
    return acc;
  }, {});

  return (
    <div className="rightPane">
      <div className="tab-container">
        <img src={backicon} className="back-icon" alt="back" onClick={() => scrollContainer('left', innerBuTabContainerRef)} />
        <div className="inner-tab-container" ref={innerBuTabContainerRef}>
          {criteriaList.map(criteria => (
            <button
              key={criteria.id}
              className={`tab-button ${criteria.id === criteriaId ? 'active' : ''}`}
              onClick={() => handleNavigateToProjectMilestones(criteria.criteria_name, criteria.id)}
            >
              {criteria.criteria_name}
            </button>
          ))}
        </div>
        <img src={fronticon} className="front-icon" alt="front" onClick={() => scrollContainer('right', innerBuTabContainerRef)} />
      </div>

      <div className="scroll-section">
        <button className="save-changes-button" onClick={handleSaveChanges}>Save Changes</button>

        {Object.entries(groupedByCategory).map(([category, subCategories], index) => (
          <div className="bu" key={index}>
            <div className="bu-header">
              <span className="text">{category || 'No Category'}</span>
              <img
                src={downarrow}
                className="down-arrow"
                alt="down"
                onClick={() => handleToggleDiv(index)}
              />
            </div>

            {openIndex === index && (
              <div className="bu-body-field-container">
                <div className="tab-container">
                  <img src={backicon} className="back-icon" alt="back" onClick={() => scrollContainer('left', innerBuTabContainerRef)} />
                  <div className="inner-tab-container" ref={innerBuTabContainerRef}>
                    {Object.keys(subCategories).map((subCat, subIndex) => (
                      <button
                        key={subIndex}
                        className={`tab-button ${activeSubCategory === subCat ? 'active' : ''}`}
                        onClick={() => handleSubCategoryClick(subCat)}
                      >
                        {subCat || 'No Sub-Category'}
                      </button>
                    ))}
                  </div>
                  <img src={fronticon} className="front-icon" alt="front" onClick={() => scrollContainer('right', innerBuTabContainerRef)} />
                </div>
                {Object.entries(subCategories).map(([subCat, items], subIndex) => (
                  activeSubCategory === subCat && (
                    <div key={subIndex}>
                      {Array.from(new Set(items.map(item => JSON.stringify(item.service_category)))).map((serviceCategory, itemIndex) => {
                        const uniqueItem = items.find(item => item.service_category === JSON.parse(serviceCategory));
                        //
                        const isExpanded = expandedStates[`${uniqueItem.category}-${uniqueItem.sub_category}-${uniqueItem.service_category}`];
                        //
                        return (
                          uniqueItem && (
                            <div className="opens-sub" key={itemIndex}>
                              <span className="text">{uniqueItem.service_category || 'No Service Category'}</span>
                              <div className="field-container">
                                <div className="field2">
                                  <label>SLA:</label>
                                  <input type="text" value={uniqueItem.sla} readOnly />
                                </div>
                                <div className="field1">
                                  <label>Priority:</label>
                                  <input type="text" value={uniqueItem.priority} readOnly />
                                </div>
                                <div className="field" style={{ width: '30%' }}>
                                  <div className="deviation-section">
                                    <div className="deviation-header">Service Level Deviation</div>
                                    <div className="deviation-fields">
                                      <div className="deviation-field deviation-low">
                                        <input type="text" value={uniqueItem.deviationLow} readOnly />
                                      </div>
                                      <div className="deviation-field deviation-med">
                                        <input type="text" value={uniqueItem.deviationMed} readOnly />
                                      </div>
                                      <div className="deviation-field deviation-high">
                                        <input type="text" value={uniqueItem.deviationHigh} readOnly />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="field3">
                                  <label>Actual Service Level:</label>
                                  <input
                                    type="text"
                                    value={uniqueItem.actualServiceLevel}
                                    onChange={(e) => handleActualServiceLevelChange(
                                      uniqueItem.category,
                                      uniqueItem.sub_category,
                                      uniqueItem.service_category,
                                      e.target.value
                                    )}
                                  />
                                </div>
                                {/*<div className="field4">
                                  <label>Remarks:</label>
                                  <textarea
                                    value={uniqueItem.remarks}
                                    onChange={(e) => handleRemarksChange(
                                      uniqueItem.category,
                                      uniqueItem.sub_category,
                                      uniqueItem.service_category,
                                      e.target.value
                                    )}
                                  />
                                </div>*/}
                                <div className="bau-field">
    <label className="remarks-label">
        <div 
            //onClick={toggleExpansion} 
            onClick={() => toggleExpansion(uniqueItem.category, uniqueItem.sub_category, uniqueItem.service_category)} 
            className="remarks-link"
        >
            <div className="arrow-icon">{isExpanded ? '▼' : '▲'}</div>
            Remarks
        </div>
        
    </label>
    </div>
    </div>
    <div className="bau-field-container1">
    {isExpanded && (
        <textarea
            value={uniqueItem.remarks}
            onChange={(e) => handleRemarksChange(uniqueItem.category, uniqueItem.sub_category, uniqueItem.service_category, e.target.value)}
            className="remarks-textarea" placeholder='Enter remarks here...'
        />
    )}
    {/* Hidden input remains but it's not displayed */}
    <input
        type="hidden"
        value={uniqueItem.remarks}
        onChange={(e) => handleRemarksChange(uniqueItem.category, uniqueItem.sub_category, uniqueItem.service_category, e.target.value)}
        className="remarks-input"
    />
</div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  )
                ))}
              </div>
            )}
          </div>
        ))}
        <Confirmationmodal
          isOpen={isModalOpen}
          message={confirmationMessage}
          isError={isError}
          onClose={() => setIsModalOpen(false)}
        />

        {isServiceModalOpen && (
          <div className="service-modal">
            <button className="close-modal" onClick={handleCloseServiceModal}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessAsUsual;

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './UpdateCriteriaBAU.css';
import updatearrow from "../../assets/updatearrow.svg";
import cross from "../../assets/cross.svg";
import editsquare from "../../assets/Edit square.svg";
import DeletePopUp from './DeletePopUp';
import EditBAUPopUp from './EditBAUPopUp';
import CONFIG from '../../config';

const UpdateCriteriaBAU = ({ isOpen, onRequestClose, onUpdateBAU, criteriaId }) => {
    const [criteriaName, setCriteriaName] = useState('');
    const [description, setDescription] = useState('');
    const [baus, setbaus] = useState([]);
    const [fetchedbaus, setFetchedbaus] = useState([]);
    const [newCriterion, setNewCriterion] = useState('');
    const [slaValue, setSlaValue] = useState('');
    const [priority, setPriority] = useState('');
    const [SLA_type, setSLA_type] = useState('');
    const [actualServiceLevel, setActualServiceLevel] = useState('');
    const [remarks, setRemarks] = useState('');
    const [deviationLow, setDeviationLow] = useState('');
    const [deviationMedium, setDeviationMedium] = useState('');
    const [deviationHigh, setDeviationHigh] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [serviceCategory, setServiceCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isSlaModalOpen, setIsSlaModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [error, setError] = useState('');

    const [categoryInput, setCategoryInput] = useState('');
    const [filteredCategories, setFilteredCategories] = useState([]);

    const [subCategoryInput, setSubCategoryInput] = useState('');
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);

    const storedUsername = localStorage.getItem('username') || '';

    useEffect(() => {
        if (isOpen && storedUsername && criteriaId) {
            fetchCriteriaContent(storedUsername, criteriaId, criteriaName);
        }
    }, [isOpen, storedUsername, criteriaId, criteriaName]);


    const fetchCategoriesAndSubCategories = () => {
        fetch(`${CONFIG.API_BASE_URL}api/categories?username=${storedUsername}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.categories) {
                    setCategories(data.categories);
                }
            })
            .catch(error => console.error('Error fetching categories:', error));

        fetch(`${CONFIG.API_BASE_URL}api/subcategories?username=${storedUsername}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.subCategories) {
                    setSubCategories(data.subCategories);
                }
            })
            .catch(error => console.error('Error fetching subCategories:', error));
    };

    const fetchCriteriaContent = (username, criteriaId) => {
        fetch(`${CONFIG.API_BASE_URL}api/criteria_content`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                criteria_id: criteriaId,
                criteriaName: criteriaName
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.data && data.data.length > 0) {
                const criteria = data.data[0];
                setCriteriaName(criteria.criteria_name);
                setDescription(criteria.criteria_desc);

                if (criteria.Service_Category) {
                    const fetchedbaus = data.data.map((item, index) => ({
                        id: criteriaId,
                        row: index + 1,
                        category: item.Category,
                        subCategory: item.Sub_Category,
                        serviceCategory: item.Service_Category,
                        sla: item.SLA_Pcnt,
                        priority: item.Priority,
                        deviationLow: item.Service_Dev_Low,
                        deviationMedium: item.Service_Dev_Med,
                        deviationHigh: item.Service_Dev_High,
                        SLA_type: item.SLA_type,
                        actualServiceLevel:item.Actual_Service_Level_percent,
                        remarks: item.remarks
                    }));
                    setFetchedbaus(fetchedbaus);
                    //mergebaus(fetchedbaus);
                }
                else{
                    setFetchedbaus([]);
                    //mergebaus(fetchedbaus);
                    }
            } else {
                console.log('No data found');
            }
        })
        .catch(error => console.error('Error fetching criteria content:', error));
    };

    useEffect(() => {
        const storedbaus = JSON.parse(localStorage.getItem('bau')) || [];
        const mergedbaus = [...storedbaus];
        if ((Array.isArray(storedbaus) && storedbaus.length === 0)) 
        {
            const existingRows = mergedbaus.map(bau => bau.row);
            fetchedbaus.forEach(fetchedbau => {
                if (!existingRows.includes(fetchedbau.row)) {
                    mergedbaus.push(fetchedbau);
                }
            });
        }
        setbaus(mergedbaus);
    }, [fetchedbaus]);

    const handleSaveEdit = (editedbau) => {
        const updatedbaus = baus.map(bau =>
            bau.row === editedbau.row ? editedbau : bau
        );
        setbaus(updatedbaus);
        onUpdateBAU(updatedbaus);
        closeEditModal();
    };

    const handleUpdatebaus = () => {
        localStorage.setItem('criteriaName', criteriaName);
        localStorage.setItem('description', description);
        localStorage.setItem('bau', JSON.stringify(baus));

        onUpdateBAU(baus);
        onRequestClose();
    };

    const openDeleteModal = (index) => {
        setDeleteIndex(index);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const handleDeleteConfirm = () => {
        if (deleteIndex !== null) {
            const updatedbaus = baus.filter((_, index) => index !== deleteIndex);
            setbaus(updatedbaus);
            onUpdateBAU(updatedbaus);
        }
        closeDeleteModal();
    };

    const openEditModal = (index) => {
        setEditData(baus[index]);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => setIsEditModalOpen(false);

    const openSlaModal = () => { 
        setIsSlaModalOpen(true);
        setCategoryInput(newCriterion);
        handleCategoryInputChange({ target: { value: newCriterion } });
    };

    const closeSlaModal = () => {
        // Clear inputs
        setCategoryInput('');
        setSubCategoryInput('');
        setServiceCategory('');
        setSlaValue('');
        setPriority('');
        setSLA_type('');
        setActualServiceLevel('');
        setRemarks('');
        setDeviationLow('');
        setDeviationMedium('');
        setDeviationHigh('');
        setCategory('');
        setSubCategory('');
        setIsSlaModalOpen(false);
  
    };

    const handleAddCriterion = () => {
        openSlaModal();
    };

    const handleSubmitCriterion = () => {
        const nextRowNumber = baus.length > 0 ? Math.max(...baus.map(bau => bau.row)) + 1 : 1;
    
        // Use categoryInput and subCategoryInput directly if no suggestions are selected
        setbaus([...baus, {
            id: criteriaId,
            row: nextRowNumber,
            category: categoryInput.trim() || category.trim() || '', // Use categoryInput if available
            subCategory: subCategoryInput.trim() || subCategory.trim() || '', // Use subCategoryInput if available
            serviceCategory: serviceCategory.trim() || '',
            sla: slaValue.trim() || '',
            priority: priority.trim() || '',
            deviationLow: deviationLow.trim() || '',
            deviationMedium: deviationMedium.trim() || '',
            deviationHigh: deviationHigh.trim() || '',
            SLA_type: SLA_type.trim() || '',
            actualServiceLevel: actualServiceLevel.trim() || '',
            remarks: remarks.trim() || ''
        }]);
        closeSlaModal();
        
    };

    useEffect(() => {
        if (isSlaModalOpen) {
            fetchCategoriesAndSubCategories();
        }
    }, [isSlaModalOpen]);

    const handleCategoryInputChange = (e) => {
        const inputValue = e.target.value || '';
        setCategoryInput(inputValue);
    
        // Filter categories based on user input
        const filtered = categories.filter((cat) =>
            cat?.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredCategories(filtered);
    };
    
    const handleSubCategoryInputChange = (e) => {
        const inputValue = e.target.value || '';
        setSubCategoryInput(inputValue);
    
        // Filter subcategories based on user input
        const filtered = subCategories.filter((subCat) =>
            subCat?.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredSubCategories(filtered);
    };
    

    const handleCategorySelect = (selectedCategory) => {
        setCategoryInput(selectedCategory);
        setFilteredCategories([]); // Clear suggestions
    };


    const handleSubCategorySelect = (selectedSubCategory) => {
        setSubCategoryInput(selectedSubCategory);
        setFilteredSubCategories([]); // Clear suggestions
    };
    const formatSlaValue = (value) => {
        // Remove any non-digit characters except the decimal point
        const numericValue = value.replace(/[^0-9.]/g, '');
    
        // Split by decimal point
        const [integerPart, decimalPart] = numericValue.split('.');
    
        // Ensure only two decimal places
        const formattedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : '';
    
        // Return formatted value
        return formattedDecimalPart ? `${integerPart}.${formattedDecimalPart}` : integerPart;
    };
    const handleSlaValueChange = (e) => {
        const formattedValue = formatSlaValue(e.target.value);
        setSlaValue(formattedValue);
    };
    useEffect(() => {
        // Adjust tbody width based on scrollbar presence
        const tbody = document.querySelector('.bau-table tbody');
        if (tbody) {
            const hasScrollbar = tbody.scrollHeight > tbody.clientHeight;
            tbody.style.width = hasScrollbar ? 'calc(100% + 7px)' : '100%';
        }
    }, [baus]); // Recalculate when milestones change

    return (
        <Modal isOpen={isOpen} className="update_modal-content-bau" overlayClassName="update_modal-overlay">
            <div className="modal-header">
                <div className="head-left"></div>
                <button type="button" className="update-criteria-button">UPDATE CRITERIA</button>
                <div className="head-right">
                    <button onClick={onRequestClose} className="close-button">&times;</button>
                </div>
            </div>
            <form className="criteria-form">
                <label>
                    Criteria Name<span className="required-field">*</span>
                    <input 
                        type="text" 
                        name="criteriaName" 
                        value={criteriaName} 
                        onChange={(e) => setCriteriaName(e.target.value)}
                    />
                    {error && <div className="error-message">{error}</div>}
                </label>
                <label>
                    Description
                    <textarea 
                        name="description" 
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </label>
            </form>
            <div className="bau-table-container">
                <table className="bau-table">
                    <thead>
                        <tr>
                            <td colSpan="10" className="mil-header">
                                <div className="add-serv-container">
                                    <span className="shift-up">Business As Usual Category: </span>
                                    <div className="add-serv">
                                        <input
                                            type="text"
                                            className="add-serv-input"
                                            placeholder="Add Parameter"
                                            value={newCriterion}
                                            onChange={(e) => setNewCriterion(e.target.value)}
                                        />
                                    </div>
                                    <img src={updatearrow} className="uparw-icon" alt="arrow" onClick={handleAddCriterion} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th width='163px'>CATEGORY</th>
                            <th width='110px'>SUBCATEGORY</th>
                            <th width='157px'>SERVICE CATEGORY</th>
                            <th width='31px'>SLA</th>
                            <th width='18px'>PRIORITY</th>
                            <th width='30px'>LOW</th>
                            <th width='30px'>MEDIUM</th>
                            <th width='33px'>HIGH</th>
                            <th width='78px'>SLA TYPE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {baus.map((bau, index) => (
                            <tr key={bau.row}>
                                <td width='140px' title={bau.category}>
                                    <div className="cell-container">
                                        <div className="cell-content">{bau.category}</div>
                                    </div>
                                </td>
                                <td width='95px'title={bau.subCategory}>
                                    <div className="cell-container">
                                        <div className="cell-content">{bau.subCategory}</div>
                                        </div>
                                </td>
                                <td width='136px' title={bau.serviceCategory}>
                                    <div className="cell-container" >
                                        <div className="cell-content" style={{ paddingRight: '40px' }}>
                                             {bau.serviceCategory}
                                            </div>
                                            <div className="icons-container">
                                    <img src={editsquare} className="edts" onClick={() => openEditModal(index)} alt="edit" />
                                    <img src={cross} className="edts" onClick={() => openDeleteModal(index)} alt="delete" />
                                </div>
                                </div>
                                </td>
                                <td width='25px'>{bau.sla}</td>
                                <td width='47px'>{bau.priority}</td>
                                <td width='25px'>{bau.deviationLow}</td>
                                <td width='42px'>{bau.deviationMedium}</td>
                                <td width='26.5px'>{bau.deviationHigh}</td>
                                <td width='66.5px'>{bau.SLA_type}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <button type="button" className="update-button" onClick={handleUpdatebaus}>Update</button>
            <Modal isOpen={isSlaModalOpen} onRequestClose={closeSlaModal} className="edit-modal-content" overlayClassName="edit-modal-overlay">
                <div className="edit-modal-header"><h2>Add Values:</h2>
                <span className="close" onClick={closeSlaModal}>&times;</span>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); handleSubmitCriterion(); }}>
                    <div className="input-container">
                        <label>Category:</label>
                        <input
                            type="text"
                            value={categoryInput}
                            onChange={handleCategoryInputChange}
                            placeholder="Enter Category"
                        />
                        {filteredCategories.length > 0 && (
                            <ul className="suggestions-dropdown">
                                {filteredCategories.map((category, index) => (
                                    <li key={index} onClick={() => handleCategorySelect(category)}>
                                        {category}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="input-container">
                        <label>Sub Category:</label>
                        <input
                            type="text"
                            value={subCategoryInput}
                            onChange={handleSubCategoryInputChange}
                            placeholder="Enter Sub Category"
                        />
                        {filteredSubCategories.length > 0 && (
                            <ul className="suggestions-dropdown">
                                {filteredSubCategories.map((subCategory, index) => (
                                    <li key={index} onClick={() => handleSubCategorySelect(subCategory)}>
                                        {subCategory}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <label>
                        Service Category:
                        <input type="text" value={serviceCategory} onChange={(e) => setServiceCategory(e.target.value)} />
                    </label>
                    <label>
                        SLA Value:
                        <input type="text" value={slaValue} onChange={handleSlaValueChange} />
                    </label>
                    <label>
                        Priority:
                        <select value={priority} onChange={(e) => setPriority(e.target.value)}>
                            <option value="">Select</option>
                            <option value="HIGH">HIGH</option>
                            <option value="NORMAL">NORMAL</option>
                            <option value="LOW">LOW</option>
                        </select>
                    </label>
                    <label>
                        Deviation Low:
                        <input type="text" value={deviationLow} onChange={(e) => setDeviationLow(e.target.value)} />
                    </label>
                    <label>
                        Deviation Medium:
                        <input type="text" value={deviationMedium} onChange={(e) => setDeviationMedium(e.target.value)} />
                    </label>
                    <label>
                        Deviation High:
                        <input type="text" value={deviationHigh} onChange={(e) => setDeviationHigh(e.target.value)} />
                    </label>
                    <label>
                        SLA Type:
                        <select value={SLA_type} onChange={(e) => setSLA_type(e.target.value)}>
                            <option value="">Select</option>
                            <option value="SLA Adherence">SLA Adherence</option>
                            <option value="SLA Deviation">SLA Deviation</option>
                        </select>
                    </label>
                    <button type="submit" className="update-button">Save</button>
                </form>
            </Modal>

            <DeletePopUp isOpen={isDeleteModalOpen} onRequestClose={closeDeleteModal} onDeleteConfirm={handleDeleteConfirm} />
            <EditBAUPopUp
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                bauData={editData}
                onSave={handleSaveEdit}
            />
        </Modal>
    );
};

export default UpdateCriteriaBAU;

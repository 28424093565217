import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import illustration from "../../assets/illustration.svg";
import pwc_bottom_logo from "../../assets/pwc_bottom_logo.svg";
import emailIcon from "../../assets/Envelope.png";
import eyeIcon from "../../assets/Invisible.png";
import eyeIconSlash from "../../assets/Visible.png";
import './Login.css';
import CONFIG from "../../config"; // Import the config file

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true"
  );
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    localStorage.removeItem("authenticated");
    localStorage.removeItem("username");
    localStorage.removeItem("criteriaName");
    localStorage.removeItem("description");
    localStorage.removeItem("milestones");
    localStorage.removeItem("bau");
    localStorage.removeItem("summaryData");
    setAuthenticated(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${CONFIG.API_BASE_URL}api/login`, { // Use the base URL from config
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    if (data.success) {
      localStorage.setItem("authenticated", "true");
      localStorage.setItem("username", username); // Store username
      setAuthenticated(true);
      if (data.user_type === 'new') {
        navigate("/updateLogin");
      } else if (data.user_type === 'existing') {
        navigate("/criteria");
      }
    } else {
      setErrorMessage(data.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <img src={pwc_bottom_logo} alt="PwC Logo" className="top_logo" />
      <div className="login-wrapper">
      <div className="login-container">
          <div className="login-header">           
            <h1>Welcome to the Service Level Agreement Management Tool</h1>
            <p>A robust platform designed for managing and monitoring Service Level Agreements (SLAs). This tool provides a centralized hub for configuring, monitoring, and optimizing service performance to align with the organizational goals.</p>
          </div>
          <div className="login-form-container">
            <form onSubmit={handleSubmit} className="login-form">
              <label>
                <span>Email</span>
                <div className="input-with-icon">
                  <input
                    type="text"
                    name="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter your email here"
                  />
                  <img src={emailIcon} alt="Email Icon" className="input-icon" />
                </div>
              </label>
              <label>
                <span>Password</span>
                <div className="input-with-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password here"
                  />
                  <img
                    src={showPassword ? eyeIcon : eyeIconSlash}
                    alt="Eye Icon"
                    className="input-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </label>
              <button type="submit">Login</button>
              {errorMessage && <p style={{ color: 'white', fontWeight: 'bold' }}>{errorMessage}</p>}
            </form>
          </div>
        </div>
        <div className="illustration-container">
          <img src={illustration} alt="Illustration" className="illustration" />
        </div>
      </div>
      <div className="footer">
        <div className="footer-content">
          <div className="logo">
            <img src={pwc_bottom_logo} alt="Logo" />
          </div>
          <div className="copyright">
            © 2018 - 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a href="https://www.pwc.com/structure">www.pwc.com/structure</a> for further details.
          </div>
          <div className="footer-links">
            <a href="#">About Site Provider</a>
            <a href="#">Privacy</a>
            <a href="#">Cookies Info</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

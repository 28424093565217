import React, { useState } from "react";
import Chart from "react-apexcharts";
import './Statistics.css'; // Import the CSS file

const PenaltyPcntChart = ({ filteredData, chartType }) => {
  const [showSLAAdherence, setShowSLAAdherence] = useState(true);

  // Function to toggle between SLA Adherence and SLA Deviation
  const toggleSLAType = (type) => {
    setShowSLAAdherence(type === "SLA Adherence");
  };

  // Filter the data based on the selected SLA type
  const displayedData = filteredData.filter(item =>
    showSLAAdherence ? item.slatype === "SLA Adherence" : item.slatype === "SLA Deviation"
  );

  const deviationColors = {
    "High Deviation": "#E0301E",
    "Medium Deviation": "#FFB600",
    "Low Deviation": "#7D7D7D"
  };

  const commonChartOptions = {
    chart: {
      height: 300,
      toolbar: {
        show: true,
        tools: {
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        },
      },
    },
    colors: displayedData.map(item => item.color),
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: displayedData.map(item => item.label),
      title: {
        text: "Service Category",
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: "Penalty %"
      }
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      followCursor: true,
      custom: ({ seriesIndex, dataPointIndex }) => {
        const dataPoint = displayedData[dataPointIndex];
        return `
          <div style="padding: 10px; background-color: #fff; color: #000; border: 1px solid #ddd; border-radius: 4px;">
            <div style="display: flex; align-items: center;">
              <div style="width: 12px; height: 12px; border-radius: 50%; background-color: ${deviationColors[dataPoint.deviation]}; margin-right: 8px;"></div>
              <div style="font-weight: bold;">${dataPoint.serviceCategory}</div>
            </div>
            <br>
            <strong>Parent Criteria:</strong> ${dataPoint.parentCriteria}<br>
            <strong>Category:</strong> ${dataPoint.category}<br>
            <strong>Sub Category:</strong> ${dataPoint.subcategory}<br>
            <strong>Deviation:</strong> ${dataPoint.deviation}<br>
            <strong>Penalty %:</strong> ${dataPoint.penaltyPcnt}
          </div>
        `;
      }
    },
    legend: {
      show: true,
      customLegendItems: Object.keys(deviationColors),
      markers: {
        fillColors: Object.values(deviationColors)
      }
    },
    markers: {
      size: 4,
      strokeWidth: 1,
      fillOpacity: 1,
      hover: {
        size: 8
      },
      colors: ['#FFB600']
    },
  };

  const chartSeries = [{
    name: "Penalty %",
    data: displayedData.map(item => item.penaltyPcnt)
  }];

  const chartOptions = {
    ...commonChartOptions,
    chart: {
      ...commonChartOptions.chart,
      type: chartType,
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        borderRadius: 4,
        colors: {
          backgroundBarRadius: 4
        },
        dataLabels: {
          position: 'top'
        },
        columnWidth: '60%',
      },
      line: {
        curve: 'smooth',
      }
    },
    stroke: {
      show: chartType === 'line',
      curve: chartType === 'line' ? 'smooth' : undefined,
      width: 3,
      colors: ['#FFB600']
    },
    fill: {
      opacity: 0.85,
    }
  };

  return (
    <div>
      <div>
        <button 
          className={`toggle-button ${showSLAAdherence ? "active" : ""}`}
          onClick={() => toggleSLAType("SLA Adherence")}
        >
          SLA Adherence
        </button>
        <button 
          className={`toggle-button ${!showSLAAdherence ? "active" : ""}`}
          onClick={() => toggleSLAType("SLA Deviation")}
        >
          SLA Deviation
        </button>
      </div>
      <Chart options={chartOptions} series={chartSeries} type={chartType} height={300} />
    </div>
  );
};

export default PenaltyPcntChart;

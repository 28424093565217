import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";
import AddCriteria from "./AddCriteria";
import UpdateCriteriaPM from "./UpdateCriteriaPM";
import UpdateCriteriaBAU from "./UpdateCriteriaBAU";
import "./Criteria.css";
import CONFIG from '../../config';

const Criteria = () => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [criteriaList, setCriteriaList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [criteriaToDelete, setCriteriaToDelete] = useState(null);
  const [username, setUsername] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [criteriaToUpdate, setCriteriaToUpdate] = useState(null);
  const [isModalBAUOpen, setIsModalBAUOpen] = useState(false);
  const initialMilestones = [];
  const initialbau = [];

  const [milestones, setMilestones] = useState(() => {
    const savedMilestones = localStorage.getItem('milestones');
    return savedMilestones ? JSON.parse(savedMilestones) : initialMilestones;
  });

  const [bau, setbau] = useState(() => {
    const savedbau = localStorage.getItem('bau');
    return savedbau ? JSON.parse(savedbau) : initialbau;
  });

  useEffect(() => {
    localStorage.setItem('milestones', JSON.stringify(milestones));
  }, [milestones]);

  useEffect(() => {
    localStorage.setItem('bau', JSON.stringify(bau));
  }, [bau]);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    const storedUsername = localStorage.getItem("username");
    if (loggedInUser === "true" && storedUsername) {
      setAuthenticated(true);
      setUsername(storedUsername);
      fetchCriteriaData(storedUsername);
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
    fetchCriteriaData(username);
  };

  const openUpdateModal = (criteria) => {
    setCriteriaToUpdate(criteria.id);
    if (criteria.criteria_name === "BAU") {
      setIsModalBAUOpen(true);
    } else {
      setIsUpdateModalOpen(true);
    }
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
    fetchCriteriaData(username);
    setCriteriaToUpdate(null);
  };

  const closeModalBAU = () => {
    setIsModalBAUOpen(false);
    fetchCriteriaData(username);
    setCriteriaToUpdate(null);
  };

  const fetchCriteriaData = async (username) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/get_criteria?username=${username}`);
      const data = await response.json();
      if (data.success) {
        setCriteriaList(data.criteria_data);
      } else {
        console.error("Failed to fetch criteria data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching criteria data", error);
    }
  };

  const handleDeleteCriteria = (criteriaId) => {
    setCriteriaToDelete(criteriaId);
    setShowDeleteModal(true);
  };

  const confirmDeleteCriteria = async () => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/delete_criteria`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ criteriaId: criteriaToDelete, username }),
      });

      if (response.ok) {
        const updatedCriteriaList = criteriaList.filter((criteria) => criteria.id !== criteriaToDelete);
        setCriteriaList(updatedCriteriaList);
        console.log("Criteria deleted successfully");
      } else {
        console.error("Failed to delete criteria");
      }
    } catch (error) {
      console.error("Error deleting criteria", error);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleUpdateMilestones = (updatedMilestones) => {
    setMilestones(updatedMilestones);
  };

  const handleUpdateBAU = (updatedbau) => {
    setbau(updatedbau);
  };

  const cancelDeleteCriteria = () => {
    setShowDeleteModal(false);
    setCriteriaToDelete(null);
  };

  const handleEvaluate = (criteriaName, criteriaId) => {
    if (criteriaName === "BAU") {
      navigate("/criteria/businessasusual", { state: { criteriaName, criteriaId } });
    } else {
      navigate("/criteria/projectmilestone", { state: { criteriaName, criteriaId } });
    }
  };

  return (
    <div className="rightPane">
      <div className="rightPane-header">CRITERIA</div>
      <div className="table-container">
        {criteriaList.map((criteria) => (
          <div className="rightPane-item" key={criteria.id}>
            <div className="rightPane-item-head">
              <div className="rightPane-title">{criteria.criteria_name}</div>
              <div className="rightPane-icons">
                <img
                  src={editIcon}
                  alt="Edit Icon"
                  className="edit-icon"
                  onClick={() => openUpdateModal(criteria)}
                />
                <img
                  src={deleteIcon}
                  alt="Delete Icon"
                  className="delete-icon"
                  onClick={() => handleDeleteCriteria(criteria.id)}
                />
              </div>
            </div>
            <div className="rightPane-description">{criteria.criteria_desc}</div>
            <button className="evaluate-buttons" onClick={() => handleEvaluate(criteria.criteria_name, criteria.id)}>Evaluate</button>
          </div>
        ))}
        <button className="add-criteria-button" onClick={openAddModal}>+</button>
        <AddCriteria isOpen={isAddModalOpen} onRequestClose={closeAddModal} />
        {criteriaToUpdate && (
          <>
            <UpdateCriteriaPM
              isOpen={isUpdateModalOpen}
              onRequestClose={closeUpdateModal}
              criteriaId={criteriaToUpdate}
              onUpdateMilestones={handleUpdateMilestones}
            />
            <UpdateCriteriaBAU
              isOpen={isModalBAUOpen}
              onRequestClose={closeModalBAU}
              criteriaId={criteriaToUpdate}
              onUpdateBAU={handleUpdateBAU}
            />
          </>
        )}
      </div>
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Are you sure you want to delete the criteria?</p>
            <button onClick={confirmDeleteCriteria}>Yes</button>
            <button onClick={cancelDeleteCriteria}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Criteria;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import fronticon from "../../assets/fronticon.svg";
import backicon from "../../assets/backicon.svg";
import './ProjectMilestone.css';
import config from '../../config';
import Confirmationmodal from "./Confirmationmodal";

const ProjectMilestone = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [username, setUsername] = useState('');
    const [criteriaName, setCriteriaName] = useState('');
    const [description, setDescription] = useState('');
    const [criteriaList, setCriteriaList] = useState([]);
    const innerBuTabContainerRef = useRef(null);
    const initialMilestones = [
        { name: '', sla: '', priority: '', SLA_type: '', actualServiceLevel: '', remarks: '' },
    ];
    const criteriaId = location.state?.criteriaId || '';
    const [milestones, setMilestones] = useState(initialMilestones);
    //const [isExpanded, setIsExpanded] = useState(false);
    const [expandedIndices, setExpandedIndices] = useState([]); // Track expanded state
    //const toggleExpansion = () => {
        //setIsExpanded(!isExpanded);
    //};
    const toggleExpansion = (index) => {
        setExpandedIndices(prev =>
            prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
        );
    };
    useEffect(() => {
        const savedMilestones = localStorage.getItem('milestones');
        
        if (savedMilestones) {
            const parsedMilestones = JSON.parse(savedMilestones);
            const filteredMilestones = parsedMilestones.filter(milestone => milestone.id === criteriaId);
            setMilestones(filteredMilestones);
        } else {
            setMilestones(initialMilestones);
        }
    }, [criteriaId]); // Depend on criteriaId to re-run effect when it changes

    const scrollContainer = (direction, containerRef) => {
        const container = containerRef.current;
        if (container) {
            const scrollAmount = direction === 'left' ? -200 : 200;
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        const storedUsername = localStorage.getItem("username");
        if (loggedInUser === "true" && storedUsername) {
            setUsername(storedUsername);
            fetchCriteriaContent(storedUsername, criteriaId, criteriaName);
            fetchCriteriaList(storedUsername);
        } else {
            navigate("/login", { replace: true });
        }
    }, [navigate, criteriaId, criteriaName]);

    const fetchCriteriaContent = (username, criteriaId, criteriaName) => {
        fetch(`${config.API_BASE_URL}api/criteria_content`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                criteria_id: criteriaId,
                criteriaName: criteriaName
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.data && data.data.length > 0) {
                const criteria = data.data[0];
                setCriteriaName(criteria.criteria_name);
                setDescription(criteria.criteria_desc);
                if (criteria.Service_Category) {
                    const fetchedMilestones = data.data.map(item => ({
                        name: item.Service_Category,
                        sla: item.SLA_Pcnt,
                        priority: item.Priority,
                        SLA_type: item.SLA_type,
                        actualServiceLevel: item.Actual_Service_Level_percent || '',
                        remarks: item.remarks || '' // Assuming this field exists
                    }));

                    // Use only the fetched milestones if no milestones are stored in localStorage
                    const storedMilestones = JSON.parse(localStorage.getItem('milestones')) || [];
                    const combinedMilestones = mergeMilestones(storedMilestones, fetchedMilestones);
                    setMilestones(combinedMilestones);
                }
            } else {
                console.log('No data found');
            }
        })
        .catch(error => {
            console.error('Error fetching criteria content:', error);
        });
    };

    const mergeMilestones = (localMilestones, fetchedMilestones) => {
        const merged = [...localMilestones];
        const localNames = localMilestones.map(milestone => milestone.name);
        if ((Array.isArray(localMilestones) && localMilestones.length === 0))
        fetchedMilestones.forEach(fetchedMilestone => {
            if (!localNames.includes(fetchedMilestone.name)) {
                merged.push(fetchedMilestone);
            }
        });

        return merged;
    };

    const fetchCriteriaList = (username) => {
        fetch(`${config.API_BASE_URL}api/get_criteria?username=${username}`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setCriteriaList(data.criteria_data);
            } else {
                console.error("Failed to fetch criteria data:", data.message);
            }
        })
        .catch(error => {
            console.error("Error fetching criteria data", error);
        });
    };

    const handleSaveChanges = () => {
        const dataToSend = milestones.map(milestone => ({
            criteria_id: criteriaId,
            parent_criteria: criteriaName,
            service_category: milestone.name,
            sla_pct: milestone.sla,
            priority: milestone.priority,
            SLA_type: milestone.SLA_type,
            Actual_service_level_percent: milestone.actualServiceLevel,
            remarks: milestone.remarks, // Include remarks in the data to send
            username: username,
        }));
    
        console.log("Data to send:", dataToSend);  // Log data to console
    
        fetch(`${config.API_BASE_URL}api/Project_milestones`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
        .then(response => {
            if (response.ok) {
                setConfirmationMessage('Criteria saved successfully!');
                setIsError(false);
                localStorage.removeItem(`milestones`);
            } else {
                return response.text().then(text => { throw new Error(text) });
            }
        })
        .catch(error => {
            console.error('Error details:', error);
            setConfirmationMessage(`Error: ${error.message}`);
            setIsError(true);
        })
        .finally(() => {
            setIsModalOpen(true); // Show modal after the fetch operation completes
        });
    };
    

    const handleMilestoneChange = (index, field, value) => {
        // Remove any characters that are not digits or a decimal point
        let cleanedValue = value.replace(/[^0-9.]/g, '');
    
        // Handle multiple decimal points
        const parts = cleanedValue.split('.');
        if (parts.length > 2) {
            cleanedValue = `${parts[0]}.${parts.slice(1).join('')}`;
        }
    
        // Limit decimal places to two
        if (parts[1] && parts[1].length > 2) {
            cleanedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
    
        // Update the state with the cleaned and formatted value
        const updatedMilestones = milestones.map((milestone, i) =>
            i === index ? { ...milestone, [field]: cleanedValue } : milestone
        );
    
        setMilestones(updatedMilestones);
    };

    const handleRemarksChange = (milestoneName, newValue) => {
        setMilestones(prevData =>
          prevData.map(item =>
            item.name === milestoneName 
              ? { ...item, remarks: newValue }
              : item
          )
        );
      };

    const handleNavigate = (criteriaName, criteriaId) => {
        if (criteriaName === "BAU") {
            navigate("/criteria/businessasusual", { state: { criteriaName, criteriaId } });
        } else {
            navigate("/criteria/projectmilestone", { state: { criteriaName, criteriaId } });
        }
    };

    return (
        <div className="rightPane">
            <div className="tab-container"> 
                <img src={backicon} className="back-icon" alt="back" onClick={() => scrollContainer('left', innerBuTabContainerRef)} />
                <div className="inner-tab-container" ref={innerBuTabContainerRef}>
                {criteriaList.map(criteria => (
                    <button 
                        key={criteria.id} 
                        className={`tab-button ${criteria.id === criteriaId ? 'active' : ''}`} 
                        onClick={() => handleNavigate(criteria.criteria_name, criteria.id)}
                    >
                        {criteria.criteria_name}
                    </button>
                ))}
                </div>
                <img src={fronticon} className="front-icon" alt="front" onClick={() => scrollContainer('right', innerBuTabContainerRef)}/>
            </div>

            <div className="scrollsection">
                <button className="save-changes-button" onClick={handleSaveChanges}>Save Changes</button>

                {milestones.map((milestone, index) => (
                    <div className="milestone" key={index}>
                        <div className="milestone-header">{milestone.name}</div>
                        <div className="milestone-field-container">
                            <div className="milestone-field">
                                <label>SLA: </label>
                                <input 
                                    type="text" 
                                    value={milestone.sla}
                                    className="sla-input" // Custom class added
                                    onChange={(e) => handleMilestoneChange(index, 'sla', e.target.value)} 
                                    readOnly
                                />
                            </div>
                            <div className="milestone-field">
                                <label>Priority: </label>
                                <input 
                                    type="text" 
                                    value={milestone.priority} 
                                    className="priority-input" // Custom class added
                                    onChange={(e) => handleMilestoneChange(index, 'priority', e.target.value)} 
                                    readOnly
                                />
                            </div>
                            <div className="milestone-field">
                                <label>SLA Type: </label>
                                <input 
                                    type="text" 
                                    value={milestone.SLA_type}
                                    className="SLAType-input"  // Custom class added
                                    readOnly
                                />
                            </div>    
                            <div className="milestone-field">
                                <label>Actual Service Level: </label>
                                <input 
                                    type="text" 
                                    value={milestone.actualServiceLevel} 
                                    onChange={(e) => handleMilestoneChange(index, 'actualServiceLevel', e.target.value)} 
                                    className="actualServiceLevel-input" // Custom class added
                                />
                            </div>
                            {/*<div className="milestone-field">
                                <label>Remarks: </label>
                                <input 
                                    type="text" 
                                    value={milestone.remarks} 
                                    onChange={(e) => handleMilestoneChange(index, 'remarks', e.target.value)} 
                                    className="remarks-input" // Custom class added
                                />
                            </div>*/}
                            {/*<div className="milestone-field">
            <label>
                <button 
                    onClick={toggleExpansion} 
                    className="toggle-link"
                >
                    {isExpanded ? '▼ Remarks' : '▲ Remarks'}
                </button>
            </label>
            {isExpanded ? (
                <textarea
                    value={milestone.remarks}
                    onChange={(e) => handleMilestoneChange(index, 'remarks', e.target.value)}
                    className="remarks-textarea"
                />
            ) : (
                <input
                    type="hidden"
                    value={milestone.remarks}
                    onChange={(e) => handleMilestoneChange(index, 'remarks', e.target.value)}
                    className="remarks-input"
                />
            )}
        </div>*/}
<div className="milestone-field">
    <label className="remarks-label">
        <span 
            /*onClick={toggleExpansion} */
            onClick={() => toggleExpansion(index)} 
            className="remarks-link"
        >
            <span className="arrow-icon">{expandedIndices.includes(index) ? '▼' : '▲'}</span>
            Remarks
        </span>
    </label>
    </div>
    </div>
    <div className="milestone-field-container1">
    {expandedIndices.includes(index) && (
        <textarea
            value={milestone.remarks}
            onChange={(e) => handleRemarksChange(milestone.name, e.target.value)}
            className="remarks-textarea" placeholder='Enter remarks here...'
        />
    )}
    {/* Hidden input remains but it's not displayed */}
    <input
        type="hidden"
        value={milestone.remarks}
        onChange={(e) => handleRemarksChange(milestone.name,  e.target.value)}
        className="remarks-input"
    />
</div>



                    </div>
                ))}
            </div>
            <Confirmationmodal
                isOpen={isModalOpen}
                message={confirmationMessage}
                isError={isError}
                onClose={() => setIsModalOpen(false)} // Close the modal when clicking the close button
            />
        </div>
    );
};


export default ProjectMilestone;

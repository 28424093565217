import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'; 

const EditPopUp = ({ isOpen, onRequestClose, milestoneData, onSave }) => {
    const [serviceLevel, setServiceLevel] = useState('');
    const [slaValue, setSlaValue] = useState('');
    const [priority, setPriority] = useState('');
    const [SLA_type, setSLA_type] = useState('');
    const [actualServiceLevel, setActualServiceLevel] = useState('');
    const [remarks, setRemarks] = useState('');
    const [milestones, setMilestones] = useState('');

    useEffect(() => {
        if (milestoneData) {
            setServiceLevel(milestoneData.name);
            setSlaValue(milestoneData.sla);
            setPriority(milestoneData.priority);
            setSLA_type(milestoneData.SLA_type);
            setActualServiceLevel(milestoneData.actualServiceLevel);
            setRemarks(milestoneData.remarks);
        }
    }, [milestoneData]);

    const handleSubmit = (event, index, field, cleanedValue) => {
        event.preventDefault();

        // Validate SLA value as a number
        if (!isNaN(slaValue) && slaValue !== '' && /^\d+(\.\d+)?$/.test(slaValue)) {
            onSave({ name: serviceLevel, sla: Number(slaValue), priority, SLA_type, actualServiceLevel, remarks });
            onRequestClose();
        } else {
            alert("Please enter a valid numeric SLA value.");
        }
    };

    const handleNumericChange = (setter) => (event) => {
        const value = event.target.value;
        // Allow only numeric values with a decimal point, ensuring numbers after decimal
        if (/^\d*\.?\d*$/.test(value) || value === '') {
            setter(value);
        }

    };

    return (
        <Modal 
            isOpen={isOpen}  
            className="edit-modal-content" 
            overlayClassName="edit-modal-overlay"
        >
            <div className="edit-modal-header">
                <h2>Edit Values</h2>
                <span className="close" onClick={onRequestClose}>&times;</span>
            </div>
            <div className="edit-modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="form-group" style={{ width: '100%' }}>
                        <label style={{ fontWeight: 'bold' }}>Service Category:</label>
                        <input 
                            type="text" 
                            name="serviceLevel" 
                            value={serviceLevel} 
                            onChange={(e) => setServiceLevel(e.target.value)} 
                        />
                    </div>
                    <div className="form-group" style={{ width: '100%' }}>
                        <label style={{ fontWeight: 'bold' }}>Enter SLA Value:</label>
                        <input 
                            type="text" 
                            name="slaValue" 
                            value={slaValue} 
                            onChange={handleNumericChange(setSlaValue)} 
                            placeholder="Enter numeric value" 
                        />
                    </div>
                    <div className="form-group" style={{ width: '100%' }}>
                        <label style={{ fontWeight: 'bold' }}>Choose Priority:</label>
                        <select 
                            name="priority" 
                            value={priority} 
                            onChange={(e) => setPriority(e.target.value)}
                        >
                            <option value="" disabled>Select</option>
                            <option value="HIGH">HIGH</option>
                            <option value="NORMAL">NORMAL</option>
                            <option value="LOW">LOW</option>
                        </select>
                    </div>
                    <div className="form-group" style={{ width: '100%' }}>
                        <label style={{ fontWeight: 'bold' }}>Choose SLA Type:</label>
                        <select 
                            name="SLA_type" 
                            value={SLA_type} 
                            onChange={(e) => setSLA_type(e.target.value)}
                        >
                            <option value="" disabled>Select</option>
                            <option value="SLA Adherence">SLA Adherence</option>
                            <option value="SLA Deviation">SLA Deviation</option>
                        </select>
                    </div>
                    <div className="edit-modal-buttons">
                        <button type="submit">Save</button>
                        <button type="button" onClick={onRequestClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default EditPopUp;

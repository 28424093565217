import { useState } from "react";
import { useNavigate } from "react-router-dom";
import illustration from "../../assets/illustration.svg";
import pwc_bottom_logo from "../../assets/pwc_bottom_logo.svg";
import eyeIconVisible from "../../assets/Visible.png";
import eyeIconHidden from "../../assets/Invisible.png";
import './Login.css';
import CONFIG from "../../config"; // Import the config file

const UpdateLogin = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage("New Password and Confirm Password do not match");
      return;
    }

    const username = localStorage.getItem("username");

    const response = await fetch(`${CONFIG.API_BASE_URL}api/update_password`, { // Use the base URL from config
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, new_password: newPassword }),
    });

    const data = await response.json();
    if (data.success) {
      setShowSuccessModal(true);
    } else {
      setErrorMessage(data.message);
    }
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate("/login");
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <img src={pwc_bottom_logo} alt="PwC Logo" className="top_logo" />
      <div className="login-wrapper">
        <div className="login-container">
          <div className="login-header">
            <h1>Update your Password</h1>
          </div>
          <div className="login-form-container">
            <form onSubmit={handleSubmit} className="login-form">
              <label>
                <span>New Password</span>
                <div className="input-with-icon">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    name="NewPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter your new password here"
                  />
                  <img
                    src={showNewPassword ? eyeIconHidden : eyeIconVisible}
                    alt="Eye Icon"
                    className="input-icon"
                    onClick={toggleNewPasswordVisibility}
                  />
                </div>
              </label>
              <label>
                <span>Confirm Password</span>
                <div className="input-with-icon">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="ConfirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your password here"
                  />
                  <img
                    src={showConfirmPassword ? eyeIconHidden : eyeIconVisible}
                    alt="Eye Icon"
                    className="input-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                </div>
              </label>
              <button type="submit">Update Password</button>
              {errorMessage && <p style={{ color: 'white', fontWeight: 'bold' }}>{errorMessage}</p>}
            </form>
          </div>
        </div>
        <div className="illustration-container">
          <img src={illustration} alt="Illustration" className="illustration" />
        </div>
      </div>
      {showSuccessModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Password updated successfully</p>
            <button onClick={handleModalClose}>Ok</button>
          </div>
        </div>
      )}
      <div className="footer">
        <div className="footer-content">
          <div className="logo">
            <img src={pwc_bottom_logo} alt="Logo" />
          </div>
          <div className="copyright">
            © 2018 - 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a href="https://www.pwc.com/structure">www.pwc.com/structure</a> for further details.
          </div>
          <div className="footer-links">
            <a href="#">About Site Provider</a>
            <a href="#">Privacy</a>
            <a href="#">Cookies Info</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateLogin;

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'; 

const EditBAUPopUp = ({ isOpen, onRequestClose, bauData, onSave }) => {
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [serviceCategory, setServiceCategory] = useState('');
    const [slaValue, setSlaValue] = useState('');
    const [priority, setPriority] = useState('');
    const [deviationLow, setDeviationLow] = useState('');
    const [deviationMedium, setDeviationMedium] = useState('');
    const [deviationHigh, setDeviationHigh] = useState('');
    const [SLA_type, setSLA_type] = useState('');
    const [actualServiceLevel, setActualServiceLevel] = useState('');
    const [remarks, setRemarks] = useState('');

    useEffect(() => {
        if (bauData) {
            setCategory(bauData.category);
            setSubCategory(bauData.subCategory);
            setServiceCategory(bauData.serviceCategory);
            setSlaValue(bauData.sla);
            setPriority(bauData.priority);
            setDeviationLow(bauData.deviationLow);
            setDeviationMedium(bauData.deviationMedium);
            setDeviationHigh(bauData.deviationHigh);
            setSLA_type(bauData.SLA_type);
            setActualServiceLevel(bauData.actualServiceLevel);
            setRemarks(bauData.remarks);
        }
    }, [bauData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Validate inputs: ensure they are numeric
        if (
            !isNaN(slaValue) && slaValue !== '' &&
            !isNaN(deviationLow) && deviationLow !== '' &&
            !isNaN(deviationMedium) && deviationMedium !== '' &&
            !isNaN(deviationHigh) && deviationHigh !== ''
        ) {
            onSave({
                id: bauData.id,
                row: bauData.row,
                category,
                subCategory,
                serviceCategory,
                sla: Number(slaValue), // Convert to number
                priority,
                deviationLow: Number(deviationLow), // Convert to number
                deviationMedium: Number(deviationMedium), // Convert to number
                deviationHigh: Number(deviationHigh), // Convert to number
                SLA_type,
                actualServiceLevel,
                remarks
            });
            onRequestClose();
        } else {
            alert("Please enter valid numeric values for SLA and deviations.");
        }
    };

    const handleNumericChange = (setter) => (event) => {
        const value = event.target.value;
        // Allow only numeric values and a single decimal point
        if (/^\d*\.?\d*$/.test(value)) {
            setter(value);
        } else {
            // Optional: Provide feedback for invalid input
            // alert("Only numeric values are allowed.");
        }
    };

    return (
        <Modal 
            isOpen={isOpen}  
            className="edit-modal-content2" 
            overlayClassName="edit-modal-overlay"
        >
            <div className="edit-modal-header">
                <h2>Edit BAU Values</h2>
                <span className="close" onClick={onRequestClose}>&times;</span>
            </div>
            <div className="edit-modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label style={{ fontWeight: 'bold' }}>Category:</label>
                        <input 
                            type="text" 
                            name="category" 
                            value={category} 
                            onChange={(e) => setCategory(e.target.value)} 
                        />
                    </div>
                    <div className="input-container">
                        <label style={{ fontWeight: 'bold' }}>SubCategory:</label>
                        <input 
                            type="text" 
                            name="subCategory" 
                            value={subCategory} 
                            onChange={(e) => setSubCategory(e.target.value)} 
                        />
                    </div>
                    <div className="input-container">
                        <label style={{ fontWeight: 'bold' }}>Service Category:</label>
                        <input 
                            type="text" 
                            name="serviceCategory" 
                            value={serviceCategory} 
                            onChange={(e) => setServiceCategory(e.target.value)} 
                        />
                    </div>
                    <div className="input-container">
                        <label style={{ fontWeight: 'bold' }}>SLA Value:</label>
                        <input 
                            type="text" // Keep as text to allow custom validation
                            name="slaValue" 
                            value={slaValue} 
                            onChange={handleNumericChange(setSlaValue)} 
                        />
                    </div>
                    <div className="input-container">
                        <label className='try2' style={{ fontWeight: 'bold' }}>Priority:</label>
                        <select 
                            name="priority" 
                            value={priority} 
                            onChange={(e) => setPriority(e.target.value)}
                        >
                            <option value="" disabled>Select</option>
                            <option value="HIGH">HIGH</option>
                            <option value="NORMAL">NORMAL</option>
                            <option value="LOW">LOW</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label style={{ fontWeight: 'bold' }}>Low:</label>
                        <input 
                            type="text" 
                            name="deviationLow" 
                            value={deviationLow} 
                            onChange={handleNumericChange(setDeviationLow)} 
                        />
                    </div>
                    <div className="input-container">
                        <label style={{ fontWeight: 'bold' }}>Medium:</label>
                        <input 
                            type="text" 
                            name="deviationMedium" 
                            value={deviationMedium} 
                            onChange={handleNumericChange(setDeviationMedium)} 
                        />
                    </div>
                    <div className="input-container">
                        <label style={{ fontWeight: 'bold' }}>High:</label>
                        <input 
                            type="text" 
                            name="deviationHigh" 
                            value={deviationHigh} 
                            onChange={handleNumericChange(setDeviationHigh)} 
                        />
                    </div>
                    <div className="input-container">
                        <label style={{ fontWeight: 'bold' }}>SLA Type:</label>
                        <select 
                            name="SLA_type" 
                            value={SLA_type} 
                            onChange={(e) => setSLA_type(e.target.value)}
                        >
                            <option value="" disabled>Select</option>
                            <option value="SLA Adherence">SLA Adherence</option>
                            <option value="SLA Deviation">SLA Deviation</option>
                        </select>
                    </div>
                    <div className="edit-modal-buttons">
                        <button type="submit">Save</button>
                        <button type="button" onClick={onRequestClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default EditBAUPopUp;

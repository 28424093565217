import React from 'react';
import Modal from 'react-modal'; 
import './DeletePopUp.css';

const DeletePopUp = ({ isOpen, onRequestClose, onDeleteConfirm }) => {
    return (
        <Modal 
            isOpen={isOpen} 
            onRequestClose={onRequestClose} 
            className="dmodal-content" 
            overlayClassName="dmodal-overlay"
        >
            <div className="dmodal-content">
                <span className="close" onClick={onRequestClose}>&times;</span>
                <p>Discard changes?</p>
                <div className="dmodal-buttons">
                    <button onClick={onDeleteConfirm}>Yes</button>
                    <button onClick={onRequestClose}>No</button>
                </div>
            </div>
        </Modal>
    );
};

export default DeletePopUp;

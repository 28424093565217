import React from "react";

export const BarChartIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="bar-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#003D2C"/>
        <stop offset="100%" stopColor="#00A87E"/>
      </linearGradient>
    </defs>
    <rect x="4" y="4" width="4" height="16" fill="url(#bar-gradient)"/>
    <rect x="10" y="8" width="4" height="12" fill="url(#bar-gradient)"/>
    <rect x="16" y="12" width="4" height="8" fill="url(#bar-gradient)"/>
  </svg>
);


export const LineChartIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="line-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#4D4D4D"/>
        <stop offset="100%" stopColor="#C4C4C4"/>
      </linearGradient>
    </defs>
    <path d="M3 17L9 11L13 15L21 7" stroke="url(#line-gradient)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

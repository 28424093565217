// useCriteriaData.js
import { useState } from "react";
import CONFIG from "../../config";

const useCriteriaData = () => {
  const [criteriaList, setCriteriaList] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState([]);

  const fetchCriteriaData = async (username) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/get_criteria?username=${username}`);
      const data = await response.json();
      if (data.success) {
        setCriteriaList(data.criteria_data);
        setSelectedCriteria(data.criteria_data.map(criteria => criteria.criteria_name)); // Select all by default
      } else {
        console.error("Failed to fetch criteria data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching criteria data", error);
    }
  };

  const handleCriteriaSelection = (criteriaName) => {
    let updatedCriteria;
    if (selectedCriteria.includes(criteriaName)) {
      updatedCriteria = selectedCriteria.filter(name => name !== criteriaName);
    } else {
      updatedCriteria = [...selectedCriteria, criteriaName];
    }
    setSelectedCriteria(updatedCriteria);
  };

  const handleSelectAll = () => {
    let updatedCriteria;
    if (selectedCriteria.length === criteriaList.length) {
      updatedCriteria = []; // Clear all selections
    } else {
      updatedCriteria = criteriaList.map(criteria => criteria.criteria_name); // Select all
    }
    setSelectedCriteria(updatedCriteria);
  };

  return {
    criteriaList,
    selectedCriteria,
    fetchCriteriaData,
    handleCriteriaSelection,
    handleSelectAll
  };
};

export default useCriteriaData;

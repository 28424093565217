import React, { useState, useMemo, useEffect } from "react";
import Chart from "react-apexcharts";
import './Statistics.css'; // Import the CSS file

const TrendChart = ({ trendData, chartType }) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const categories = useMemo(() => trendData.map(item => `${monthNames[item.month - 1]}-${item.year}`), [trendData]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showSLAType, setShowSLAType] = useState("SLA Adherence");

  const toggleSLAType = (type) => {
    setShowSLAType(type);
  };

  const filteredTrendData = useMemo(() => {
    return trendData.map(item => ({
      ...item,
      data: item.data.filter(entry => entry.SLA_type === showSLAType)
    }));
  }, [trendData, showSLAType]);

  const groupedSubCategories = useMemo(() => {
    const grouped = {};
    filteredTrendData.forEach(item => {
      item.data.forEach(entry => {
        if (!grouped[entry.Parent_criteria]) {
          grouped[entry.Parent_criteria] = [];
        }
        if (entry.sub_category) {
          if (!grouped[entry.Parent_criteria].includes(entry.sub_category)) {
            grouped[entry.Parent_criteria].push(entry.sub_category);
          }
        } else if (!grouped[entry.Parent_criteria].includes(entry.Parent_criteria)) {
          grouped[entry.Parent_criteria].push(entry.Parent_criteria);
        }
      });
    });
    return grouped;
  }, [filteredTrendData]);

  useEffect(() => {
    const filtered = new Set();
    filteredTrendData.forEach(item => {
      item.data.forEach(entry => {
        const isSelectedSubCategoryParentCriteria = entry.Parent_criteria === selectedSubCategory;
        if (
          !selectedSubCategory ||
          entry.sub_category === selectedSubCategory ||
          isSelectedSubCategoryParentCriteria
        ) {
          filtered.add(entry.service_category);
        }
      });
    });
    setFilteredCategories(Array.from(filtered));
    setSelectedCategory("");
  }, [selectedSubCategory, filteredTrendData]);

  const filteredData = useMemo(() => {
    if (!selectedCategory && !selectedSubCategory) return filteredTrendData;

    return filteredTrendData.map(item => ({
      ...item,
      data: item.data.filter(entry => {
        const isSelectedSubCategoryParentCriteria = entry.Parent_criteria === selectedSubCategory;
        return (
          (selectedCategory ? entry.service_category === selectedCategory : true) &&
          (isSelectedSubCategoryParentCriteria || !selectedSubCategory || entry.sub_category === selectedSubCategory)
        );
      })
    }));
  }, [filteredTrendData, selectedCategory, selectedSubCategory]);

  const groupedData = useMemo(() => {
    return filteredData.reduce((acc, item) => {
      item.data.forEach(entry => {
        const category = entry.service_category;
        if (!acc[category]) {
          acc[category] = new Array(categories.length).fill(0);
        }
        const index = categories.indexOf(`${monthNames[item.month - 1]}-${item.year}`);
        if (index > -1) {
          acc[category][index] = parseFloat(entry.Actual_Service_Level_percent) || 0;
        }
      });
      return acc;
    }, {});
  }, [filteredData, categories, monthNames]);

  const chartSeries = useMemo(() => Object.keys(groupedData).map(serviceCategory => ({
    name: serviceCategory,
    data: groupedData[serviceCategory],
  })), [groupedData]);

  useEffect(() => {
    const hasSubCategory = trendData.some(item => 
      item.data.some(entry => entry.sub_category)
    );

    if (!hasSubCategory) {
      const defaultSubCategory = trendData.length > 0 ? trendData[0].data[0].Parent_criteria : "";
      setSelectedSubCategory(defaultSubCategory);
    } else {
      setSelectedSubCategory("");
    }
  }, [trendData]);

  const chartOptions = useMemo(() => ({
    chart: {
      type: chartType,
      height: 400,
      toolbar: {
        show: true,
        tools: {
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        }
      },
    },
    colors: [
      "#E0301E", "#FFB600", "#7D7D7D", "#FD6412", "#A30000", 
      "#FFD700", "#505050", "#FF8C00", "#CC3333", "#FFCC00",
      "#B5B5B5", "#FF4500"
    ],
    xaxis: {
      categories,
      title: {
        text: "Month-Year",
      },
    },
    yaxis: {
      title: {
        text: "Actual SLA (%)",
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const dataEntry = filteredData.find(item => {
          const monthYear = `${monthNames[item.month - 1]}-${item.year}`;
          return monthYear === categories[dataPointIndex];
        });

        const hoveredData = dataEntry?.data[seriesIndex];

        if (hoveredData) {
          return (
            `<div style="padding: 10px; background-color: white; border: 1px solid #ccc;">
              <strong>Parent Criteria:</strong> ${hoveredData.Parent_criteria} <br />
              <strong>Sub Category:</strong> ${hoveredData.sub_category || 'N/A'} <br />
              <strong>Service Category:</strong> ${hoveredData.service_category} <br />
              <strong>Month-Year:</strong> ${categories[dataPointIndex]} <br />
              <strong>Actual SLA:</strong> ${hoveredData.Actual_Service_Level_percent}% <br />
            </div>`
          );
        }
        return '';
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        colors: {
          backgroundBarRadius: 4
        },
        dataLabels: {
          position: 'top'
        },
        columnWidth: '60%',
      },
      line: {
        curve: 'smooth',
      }
    },
    stroke: {
      show: chartType === 'line',
      curve: chartType === 'line' ? 'smooth' : undefined,
      width: 3
    },
    fill: {
      opacity: 0.85,
    },
    legend: {
      show:false
    },
    markers: {
      size: 5,
      strokeWidth: 1,
      fillOpacity: 1,
      hover: {
        size: 8,
        sizeOffset: 3,
      },
    }
  }), [chartType, categories, chartSeries, filteredData]);

  return (
    <div className="trend-chart-container">
      <div className="category-dropdown-container">
        <label className="category-dropdown">
          Sub-Category:
          <select className="category-dropdown-select" onChange={e => setSelectedSubCategory(e.target.value)} value={selectedSubCategory}>
            <option value="">All</option>
            {Object.keys(groupedSubCategories).map((category) => (
              <optgroup key={category} label={category}>
                {groupedSubCategories[category].map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </label>
        <label className="category-dropdown">
          Service Category:
          <select className="category-dropdown-select" onChange={e => setSelectedCategory(e.target.value)} value={selectedCategory} disabled={!selectedSubCategory}>
            <option value="">All</option>
            {filteredCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div >
        <button 
          className={`toggle-button ${showSLAType === "SLA Adherence" ? "active" : ""}`}
          onClick={() => toggleSLAType("SLA Adherence")}
        >
          SLA Adherence
        </button>
        <button 
          className={`toggle-button ${showSLAType === "SLA Deviation" ? "active" : ""}`}
          onClick={() => toggleSLAType("SLA Deviation")}
        >
          SLA Deviation
        </button>
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type={chartType}
        height={400}
      />
    </div>
  );
};

export default TrendChart;

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // For tables in PDF
import downarrow from "../../assets/downarrow.png";
import "./Report.css";
import CONFIG from "../../config"; // Import the config file

const Report = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const [criteriaList, setCriteriaList] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Refs for dropdown elements
  const exportOptionsRef = useRef(null);
  const criteriaOptionsRef = useRef(null);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    const storedUsername = localStorage.getItem("username");
    if (loggedInUser === "true" && storedUsername) {
      fetchCriteriaData(storedUsername);
      fetchReportData(storedUsername);
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    const processFilteredData = async () => {
      const dataToProcess = selectedCriteria
        ? reportData.filter(item =>
            item.Parent_Criteria === selectedCriteria || 
            (!item.isParent && item.Parent_Criteria === selectedCriteria)
          )
        : reportData;

      const processedFilteredData = [];
      let currentParent = null;
      let currentCategory = null;
      let currentSubCategory = null;
      let serialNumber = 1;

      for (const item of dataToProcess) {
        if (item.Parent_Criteria !== currentParent) {
          currentParent = item.Parent_Criteria;
          processedFilteredData.push({
            Service_Category: currentParent,
            isParent: true,
            serialNumber: null,
            Deviation: item.Project_Deviation,
            Penalty_Pcnt: item.Avg_Penalty
          });
          currentCategory = null;
          currentSubCategory = null;
          serialNumber = 1;
        }

        if (item.Category && item.Category !== currentCategory) {
          currentCategory = item.Category;
          processedFilteredData.push({
            Service_Category: currentCategory,
            isCategory: true,
            serialNumber: null,
            Deviation: item.Deviation_category || "N/A",
            Penalty_Pcnt: item.Penalty_Pcnt_category || "N/A"
          });
          currentSubCategory = null;
        }

        if (item.Sub_Category && item.Sub_Category !== currentSubCategory) {
          currentSubCategory = item.Sub_Category;
          processedFilteredData.push({
            Service_Category: currentSubCategory,
            isSubCategory: true,
            serialNumber: null,
            Deviation: item.Deviation_subcategory || "N/A",
            Penalty_Pcnt: item.Penalty_Pcnt_subcategory || "N/A"
          });
        }

        processedFilteredData.push({
          ...item,
          serialNumber: item.isParent || item.isCategory || item.isSubCategory ? null : serialNumber++
        });
      }
      setFilteredData(processedFilteredData);
    };
    processFilteredData();
  }, [selectedCriteria]);

  const fetchCriteriaData = async (username) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/get_criteria?username=${username}`);
      const data = await response.json();
      if (data.success) {
        setCriteriaList(data.criteria_data);
      } else {
        console.error("Failed to fetch criteria data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching criteria data", error);
    }
  };

  const fetchReportData = async (username) => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/criteria?username=${username}`);
      const data = await response.json();

      if (Array.isArray(data)) {
        const processedData = [];
        let currentCategory = null;
        let currentSubCategory = null;
        let currentParent = null;

        data.forEach(item => {
          if (item.Parent_Criteria !== currentParent) {
            currentParent = item.Parent_Criteria;
            processedData.push({
              Service_Category: currentParent,
              isParent: true,
              serialNumber: null,
              Deviation: item.Project_Deviation,
              Penalty_Pcnt: item.Avg_Penalty
            });
            currentCategory = null;
            currentSubCategory = null;
          }

          if (item.Category !== currentCategory) {
            currentCategory = item.Category;
            processedData.push({
              Service_Category: currentCategory,
              isCategory: true,
              serialNumber: null,
              Deviation: item.Deviation_category || " ",
              Penalty_Pcnt: item.Penalty_Pcnt_category || " "
            });
            currentSubCategory = null;
          }

          if (item.Sub_Category !== currentSubCategory) {
            currentSubCategory = item.Sub_Category;
            processedData.push({
              Service_Category: currentSubCategory,
              isSubCategory: true,
              serialNumber: null,
              Deviation: item.Deviation_subcategory || " ",
              Penalty_Pcnt: item.Penalty_Pcnt_subcategory || " "
            });
          }

          processedData.push({
            ...item,
            isParent: false,
            isCategory: false,
            isSubCategory: false,
            serialNumber: item.serialNumber || null
          });
        });
        console.log(processedData);
        setReportData(processedData);
        setFilteredData(processedData);
      } else {
        console.error("Failed to fetch report data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching report data", error);
    }
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.table_to_sheet(document.querySelector('.report-table'));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '.report-table' });
    doc.save('report.pdf');
  };

  const handleCriteriaSelection = (criteria) => {
    setSelectedCriteria(criteria);
    setOpenDropdown(null); // Close all dropdowns when selecting criteria
  };

  // Handle clicks outside of dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (exportOptionsRef.current && !exportOptionsRef.current.contains(event.target)) {
        if (openDropdown === 'exportOptions') {
          setOpenDropdown(null); // Close export options dropdown
        }
      }
      if (criteriaOptionsRef.current && !criteriaOptionsRef.current.contains(event.target)) {
        if (openDropdown === 'criteriaOptions') {
          setOpenDropdown(null); // Close criteria options dropdown
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  const toggleDropdown = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null); // Close if the same dropdown is clicked
    } else {
      setOpenDropdown(dropdown); // Open the clicked dropdown and close others
    }
  };

  return (
    <div>
      <div className="rightPane">
        <div className="rightPane-header">REPORT</div>
        <div className="dropdown-container">
          <div
            className="dropdown"
            ref={exportOptionsRef}
          >
            <div
              className="dropdown-header"
              onClick={() => toggleDropdown('exportOptions')}
            >
              Export Options
              <img
                src={downarrow}
                alt="Arrow"
                className={`arrow-icon ${openDropdown === 'exportOptions' ? "down-arrow" : "down-arrow"}`}
              />
            </div>
            {openDropdown === 'exportOptions' && (
              <div className="dropdown-content">
                <div onClick={exportToExcel}>Excel</div>
                <div onClick={exportToPDF}>PDF</div>
              </div>
            )}
          </div>
          <div
            className="dropdown"
            ref={criteriaOptionsRef}
          >
            <div
              className="dropdown-header"
              onClick={() => toggleDropdown('criteriaOptions')}
            >
              Select Criteria
              <img
                src={downarrow}
                alt="Arrow"
                className={`arrow-icon ${openDropdown === 'criteriaOptions' ? "down-arrow" : "down-arrow"}`}
              />
            </div>
            {openDropdown === 'criteriaOptions' && (
              <div className="dropdown-content">
                {criteriaList.map((criteria) => (
                  <div key={criteria.id} onClick={() => handleCriteriaSelection(criteria.criteria_name)}>
                    {criteria.criteria_name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="table-container">
          <table className="report-table">
            <thead>
              <tr>
                <th rowSpan="2">Sr No</th>
                <th rowSpan="2">Service category</th>
                <th rowSpan="2">SLA</th>
                <th rowSpan="2">Priority</th>
                <th colSpan="3">Service Level Deviation</th>
                <th rowSpan="2">Actual SLA</th>
                <th rowSpan="2">Deviation</th>
                <th rowSpan="2">Priority Score</th>
                <th rowSpan="2">Penalty %</th>
              </tr>
              <tr>
                <th>Low</th>
                <th>Medium</th>
                <th>High</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                item.isParent ? (
                  <tr key={index} className="parent-row">
                    <td colSpan="8">{item.Service_Category}</td>
                    <td>{item.Deviation}</td>
                    <td></td>
                    <td>{item.Penalty_Pcnt}</td>
                  </tr>
                ) : item.isCategory ? (
                  <tr key={index} className="category-row">
                    <td colSpan="8">{item.Service_Category}</td>
                    <td>{item.Deviation}</td>
                    <td></td>
                    <td>{item.Penalty_Pcnt}</td>
                  </tr>
                ) : item.isSubCategory ? (
                  <tr key={index} className="sub-category-row">
                    <td colSpan="8">{item.Service_Category}</td>
                    <td>{item.Deviation}</td>
                    <td></td>
                    <td>{item.Penalty_Pcnt}</td>
                  </tr>
                ) : (
                  <tr key={index} className="service-category-row">
                    <td>{item.serialNumber || ""}</td>
                    <td>{item.Service_Category}</td>
                    <td>{item.SLA_Pcnt}</td>
                    <td>{item.Priority}</td>
                    <td>{item.Service_Dev_Low}</td>
                    <td>{item.Service_Dev_Med}</td>
                    <td>{item.Service_Dev_High}</td>
                    <td>{item.Actual_Service_Level_percent}</td>
                    <td>{item.Deviation}</td>
                    <td>{item.Priority_Score !== null ? item.Priority_Score : "N/A"}</td>
                    <td>{item.Penalty_Pcnt}</td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Report;

import React from 'react';

const WeightageValidationModal = ({ message, isOpen, onClose, isError }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
    <div className={`modal-content ${isError ? 'error' : 'success'}`}>
      <p className="modal-message">{message}</p>
      <button className="modal-button" onClick={onClose}>Close</button>
    </div>
  </div>
  );
};

export default WeightageValidationModal;
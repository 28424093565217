import React, { useState } from 'react';
import Modal from 'react-modal';
import './UpdateCriteriaPM.css';
import updatearrow from "../../assets/updatearrow.svg";
import cross from "../../assets/cross.svg";
import editsquare from "../../assets/Edit square.svg";
import DeletePopUp from "./DeletePopUp";
import EditPopUp from "./EditPopUp";
import CONFIG from '../../config';

const AddCriteria= ({ isOpen, onRequestClose, onUpdateMilestones }) => {
    const initialMilestones = [ ];
    const [criteriaName, setCriteriaName] = useState('');
    const [description, setDescription] = useState('');
    const [username, setUsername] = useState("");
    const [milestones, setMilestones] = useState(initialMilestones);
    const [newCriterion, setNewCriterion] = useState('');
    const [slaValue, setSlaValue] = useState('');
    const [priority, setPriority] = useState('');
    const [isSlaModalOpen, setIsSlaModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [error, setError] = useState('');

    const storedUsername = localStorage.getItem("username");

    const handleUpdate = () => {
        if (!criteriaName.trim()) {
            setError('Criteria Name is required');
            return;
        }
    
        // Backend API call
        fetch(`${CONFIG.API_BASE_URL}api/add_criteria`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: storedUsername, // Replace with actual username
                criteriaName: criteriaName,
                criteriaDesc: description,
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log("Criteria added successfully:", data.message);
            } else {
                console.error('Failed to add criteria:', data.message);
            }
            onRequestClose();
        })
        .catch(error => {
            console.error('Error adding criteria:', error);
        });
    };
    return (
        <Modal isOpen={isOpen} className="update_modal-content" overlayClassName="update_modal-overlay">
            <div className="modal-header">
                <div className="head-left"></div>
                <button type="button" className="update-criteria-button">ADD CRITERIA</button>
                <div className="head-right">
                    <button onClick={onRequestClose} className="close-button">&times;</button>
                </div>
            </div>
            <form className="criteria-form">
                <label>
                    Criteria Name<span className="required-field">*</span>
                    <input 
                        type="text" 
                        name="criteriaName" 
                        value={criteriaName} 
                        onChange={(e) => setCriteriaName(e.target.value)}
                    />
                    {error && <div className="error-message">{error}</div>}
                </label>
                <label>
                    Description
                    <textarea 
                        name="description" 
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </label>
                <button type="button" className="update-button" onClick={handleUpdate}>Submit</button>
            </form>
        </Modal>
    );
};

export default AddCriteria;

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // For tables in PDF
import downarrow from "../../assets/downarrow.png";
import leftarrow from "../../assets/leftarrow.png";
import "./Summary.css";
import config from '../../config'; // Import the config file
import Confirmationmodal from "../Criteria/Confirmationmodal";
import WeightageValidationModal from "./WeightageValidationModal"; // Import the new component

const Summary = () => {
  const [username, setUsername] = useState("");
  const [summaryData, setSummaryData] = useState([]);
  const [Parent_Criteria, setParent_Criteria] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showCriteriaOptions, setShowCriteriaOptions] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const [criteriaList, setCriteriaList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [contractValues, setContractValues] = useState({});
  const [weightageValues, setWeightageValues] = useState({});
  const [evaluatedData, setEvaluatedData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWeightageValidationModalOpen, setIsWeightageValidationModalOpen] = useState(false);
  const navigate = useNavigate();
  // Refs for dropdowns
  const exportOptionsRef = useRef(null);
  const criteriaOptionsRef = useRef(null);
   // Close dropdown if clicked outside
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        exportOptionsRef.current && !exportOptionsRef.current.contains(event.target) &&
        criteriaOptionsRef.current && !criteriaOptionsRef.current.contains(event.target)
      ) {
        setShowExportOptions(false);
        setShowCriteriaOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    const storedUsername = localStorage.getItem("username");
    if (loggedInUser === "true" && storedUsername) {
      setUsername(storedUsername);
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    if (username) {
      fetchCriteriaData(username);
    }
  }, [username]);

  useEffect(() => {
    if (username && Parent_Criteria.length > 0) {
      fetchSummaryData(username, Parent_Criteria);
    }
  }, [username, Parent_Criteria]);

  const fetchSummaryData = async (username, Parent_Criteria) => {
    try {
      if (!Parent_Criteria.length) {
        console.warn("Parent_Criteria is empty. Aborting fetch.");
        return;
      }
  
      const storedData = JSON.parse(localStorage.getItem('summaryData')) || {};
  
      const initialContractValues = {};
      const initialWeightageValues = {};
      Parent_Criteria.forEach(criteria => {
        if (storedData[criteria]) {
          // Alert each category stored under this criteria
          Object.keys(storedData[criteria].categories || {}).forEach(category => {
            if (storedData[criteria].categories[category]) {
                // Set weightage values
                initialContractValues[criteria] = storedData[criteria].contractValue || {};
                initialWeightageValues[criteria] = initialWeightageValues[criteria] || {};
                initialWeightageValues[criteria][category] = storedData[criteria].categories[category];
            }
            setContractValues(initialContractValues);
            setWeightageValues(initialWeightageValues);
        });
        }
         // If data not present in localStorage, fetch from API
        fetchFromAPI(criteria, initialContractValues, initialWeightageValues);
      });
  
    } catch (error) {
      console.error('Error fetching summary data:', error);
    }
  };
  
  const fetchFromAPI = async (criteria, initialContractValues, initialWeightageValues) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/summary_data`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, Parent_Criteria: [criteria] })
      });
     
      const data = await response.json();
      setSummaryData(data);
      if (data.length > 0) {
        data.forEach(item => {
          const { Parent_Criteria, Category, Weightage } = item;
          if (!initialContractValues[Parent_Criteria]) {
            initialContractValues[Parent_Criteria] = '100000';
          }
          if (!initialWeightageValues[Parent_Criteria]) {
            initialWeightageValues[Parent_Criteria][Category] = Weightage || '';
          }
        });
      }
      setContractValues(initialContractValues);
      setWeightageValues(initialWeightageValues);
    } catch (error) {
      console.error('Error fetching data from API:', error);
    }
  };
  


  const fetchCriteriaData = async (username) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/get_criteria?username=${username}`);
      const data = await response.json();
      if (data.success) {
        setCriteriaList(data.criteria_data);
        // Assuming you need to set Parent_Criteria from the fetched data
        const initialParentCriteria = data.criteria_data.map(criteria => criteria.criteria_name);
        setParent_Criteria(initialParentCriteria);
      } else {
        console.error("Failed to fetch criteria data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching criteria data", error);
    }
  };

  const validateWeightage = () => {
    console.log("Validating weightages...");
  
    // Iterate over each parent criteria
    for (const parentCriteria of Object.keys(weightageValues)) {
      const categories = weightageValues[parentCriteria];
      
      // Calculate total weightage for the current parent criteria
      const totalWeightage = Object.values(categories).reduce((total, weightage) => {
        return total + parseFloat(weightage) || 0; // Add weights, default to 0 if NaN
      }, 0);
  
      console.log(`Parent Criteria: ${parentCriteria}`);
      console.log(`Total Weightage: ${totalWeightage}`);
  
      // Check if the total weightage is exactly 100
      if (totalWeightage !== 100) {
        console.log("Weightage validation failed");
        setIsWeightageValidationModalOpen(true);
        return false; // Return false if validation fails
      }
    }
    console.log("Weightage validation passed");
    return true; // Return true if all criteria are valid
  };
  
  const exportToExcel = () => {
    const tables = document.querySelectorAll('.summary-table');
    const wb = XLSX.utils.book_new();
    tables.forEach((table, index) => {
      const ws = XLSX.utils.table_to_sheet(table);
      XLSX.utils.book_append_sheet(wb, ws, `Table ${index + 1}`);
    });
    XLSX.writeFile(wb, 'summary.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tables = document.querySelectorAll('.summary-table');
    tables.forEach((table, index) => {
      if (index > 0) doc.addPage();
      doc.autoTable({ html: table });
    });
    doc.save('summary.pdf');
  };

  const handleContractValueChange = (parentCriteria, e) => {
    setContractValues(prevValues => ({
      ...prevValues,
      [parentCriteria]: e.target.value
    }));
  };

  const handleWeightageChange = (parentCriteria, category, e) => {
    setWeightageValues(prevValues => ({
      ...prevValues,
      [parentCriteria]: {
        ...prevValues[parentCriteria],
        [category]: e.target.value
      }
    }));
  };

  const handleUpdate = () => {
    if (isEditing) {
      const localStorageData = {};
      Object.keys(contractValues).forEach(parentCriteria => {
        const categories = {};
        
        Object.keys(weightageValues[parentCriteria] || {}).forEach(category => {
          categories[category] = weightageValues[parentCriteria][category];
        });
  
        localStorageData[parentCriteria] = {
          contractValue: contractValues[parentCriteria],
          categories: categories
        };
      });
      localStorage.setItem('summaryData', JSON.stringify(localStorageData));
      setIsEditing(false);
      setConfirmationMessage('Values Updated Successfully.Please go for Evaluate.');
      setIsModalOpen(true); // Show modal after fetch operation
      setIsError(false);
    } else {
      // If we are not in editing mode, enable editing
      setIsEditing(true);
    }
   
  };

  const handleEvaluate = () => {
    if (validateWeightage()) {
      const requestData = Object.keys(contractValues).map(parentCriteria => ({
        Parent_Criteria: parentCriteria,
        Contract_Value: parseFloat(contractValues[parentCriteria]).toFixed(2), // Round to 2 decimal places
        Rows: Object.keys(groupedData[parentCriteria] || {}).map(category => ({
          Service_Category: category,
          Category_Weightage: parseFloat(weightageValues[parentCriteria]?.[category] || 0).toFixed(2), // Round to 2 decimal places
          Deviation: category === parentCriteria
            ? groupedData[parentCriteria][category]?.Project_Deviation || ''
            : groupedData[parentCriteria][category]?.Deviation_category || '',
          Penalty_Percentage: category === parentCriteria
            ? groupedData[parentCriteria][category]?.Avg_Penalty || ''
            : groupedData[parentCriteria][category]?.Penalty_Pcnt_category || ''
        }))
      }));

      fetch(`${config.API_BASE_URL}/api/summary_evaluate`, { // Use config for API base URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, requestData })
      })
        .then(response => response.json())
        .then(data => {
          setEvaluatedData(data);
          const updatedGroupedData = { ...groupedData };
          data.forEach(parentCriteriaData => {
            const { Parent_Criteria, Evaluated_Rows, Total_Penalty, Penalty_value } = parentCriteriaData;

            if (updatedGroupedData[Parent_Criteria]) {
              Evaluated_Rows.forEach(row => {
                const { Service_Category } = row;
                if (updatedGroupedData[Parent_Criteria][Service_Category]) {
                  updatedGroupedData[Parent_Criteria][Service_Category] = {
                    ...updatedGroupedData[Parent_Criteria][Service_Category],
                    ...row,
                    Penalty: parseFloat(row.Penalty || 0).toFixed(2) // Round to 2 decimal places
                  };
                } else {
                  updatedGroupedData[Parent_Criteria][Service_Category] = {
                    ...row,
                    Penalty: parseFloat(row.Penalty || 0).toFixed(2) // Round to 2 decimal places
                  };
                }
              });
              updatedGroupedData[Parent_Criteria].Total_Penalty = parseFloat(Total_Penalty || 0).toFixed(2); // Round to 2 decimal places
              updatedGroupedData[Parent_Criteria].Penalty_Value = parseFloat(Penalty_value || 0).toFixed(2); // Round to 2 decimal places
            }
          });

          const updatedSummaryData = Object.values(updatedGroupedData).flatMap(parentData =>
            Object.values(parentData)
          );

          console.log("Updated Summary Data:", updatedSummaryData);
          setSummaryData(updatedSummaryData);
        })
        .catch(error => {
          console.error('Error details:', error);
        })
    }
  };

  const handleCriteriaSelection = async (criteria) => {
    if (!criteria) {
      console.warn("Invalid criteria selected.");
      return;
    }

    setSelectedCriteria(criteria);
    setShowCriteriaOptions(false);

    try {
      const response = await fetch(`${config.API_BASE_URL}/api/summary_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: localStorage.getItem("username"),
          criteria: criteria
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("API Response Data:", data);

      if (Array.isArray(data)) { // Check if data is an array
        setSummaryData(data);
      } else {
        console.error("Failed to fetch data for the selected criteria:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data for the selected criteria:", error);
    }
  };

  const handleCloseServiceModal = () => setIsServiceModalOpen(false);
  const handleCloseWeightageValidationModal = () => setIsWeightageValidationModalOpen(false);

  const groupedData = summaryData.reduce((acc, item) => {
    const { Parent_Criteria, Category } = item;

    if (!Parent_Criteria || (Category === undefined && Parent_Criteria === undefined)) {
      console.warn('Invalid item encountered:', item);
      return acc;
    }

    if (!acc[Parent_Criteria]) {
      acc[Parent_Criteria] = {};
    }

    const categoryKey = Category || Parent_Criteria;

    if (!acc[Parent_Criteria][categoryKey]) {
      acc[Parent_Criteria][categoryKey] = item;
    }
    return acc;
  }, {});

  useEffect(() => {
    console.log('Grouped Data:', groupedData);
  }, [summaryData]);

  return (
    <div className="rightPane">
      <div className="rightPane-header">Summary</div>
      <div className="dropdown-container">
      <div className="dropdown" ref={exportOptionsRef}>
          <div
            className="dropdown-header"
            onClick={() => {
              setShowExportOptions(!showExportOptions)
              setShowCriteriaOptions(false)
            }}
          >
            Export Options
            <img
              //src={showExportOptions ? downarrow : leftarrow}
              src={showExportOptions ? downarrow : downarrow}
              alt="Arrow"
              //className={`arrow-icon ${showExportOptions ? "down-arrow" : "left-arrow"}`}
              className={`arrow-icon ${showExportOptions ? "down-arrow" : "down-arrow"}`}
            />
          </div>
          {showExportOptions && (
            <div className="dropdown-content">
              <div onClick={exportToExcel}>Excel</div>
              <div onClick={exportToPDF}>PDF</div>
            </div>
          )}
        </div>
        <div className="dropdown" ref={criteriaOptionsRef}>
          <div
            className="dropdown-header"
            onClick={() => {
              setShowCriteriaOptions(!showCriteriaOptions)
              setShowExportOptions(false)
            }}
          >
            Select Criteria
            <img
              src={showCriteriaOptions ? downarrow : downarrow}
              alt="Arrow"
              className={`arrow-icon ${showCriteriaOptions ? "down-arrow" : "down-arrow"}`}
            />
          </div>
          {showCriteriaOptions && (
            <div className="dropdown-content">
              {criteriaList.map((criteria) => (
                <div
                  key={criteria.id}
                  onClick={() => handleCriteriaSelection(criteria.criteria_name)}
                >
                  {criteria.criteria_name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="table-container-summary">
        {Object.keys(groupedData).map((parentCriteria, index) => {
          const categories = groupedData[parentCriteria];
          const hasCategories = Object.keys(categories).length > 1;

          return (
            <div className="summary-section" key={index}>
              <h2>{parentCriteria}</h2>

              <div className="contract-value-box">
                <div className="contract-value-header">Contract Value</div>
                {isEditing ? (
                  <input
                    type="text"
                    value={contractValues[parentCriteria] || ''}
                    onChange={(e) => handleContractValueChange(parentCriteria, e)}
                    className="contract-value-input"
                  />
                ) : (
                  <div className="contract-value">
                    {contractValues[parentCriteria] || ''}
                  </div>
                )}
              </div>

              {isWeightageValidationModalOpen && (
                <WeightageValidationModal
                  isOpen={isWeightageValidationModalOpen}
                  message="Sum of Category Weightage must be equal to 100"
                  onClose={handleCloseWeightageValidationModal}
                />
              )}

              <table className="summary-table">
                <thead>
                  <tr>
                    <th>SR.NO.</th>
                    <th>SERVICE CATEGORY</th>
                    <th>CATEGORY WEIGHTAGE</th>
                    <th>DEVIATION</th>
                    <th>PENALTY %</th>
                    <th>PENALTY</th>
                  </tr>
                </thead>
                <tbody>
                  {hasCategories ? (
                    Object.keys(categories).map((category, idx) => {
                      const data = categories[category] || {}; // Default to empty object if data is undefined
                      const {
                        Deviation_category = '', // Default to empty string if undefined
                        Penalty_Pcnt_category = '', // Default to empty string if undefined
                        Penalty = '' // Default to empty string if undefined
                      } = data;

                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{category}</td>
                          <td>{isEditing ? (
                            <input
                              type="text"
                              value={weightageValues[parentCriteria]?.[category] || ''}
                              onChange={(e) => handleWeightageChange(parentCriteria, category, e)}
                            />
                          ) : weightageValues[parentCriteria]?.[category]}</td>
                          <td>{Deviation_category}</td>
                          <td>{Penalty_Pcnt_category}</td>
                          <td>{Penalty}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>1</td>
                      <td>{parentCriteria}</td>
                      <td>{isEditing ? (
                        <input
                          type="text"
                          value={weightageValues[parentCriteria]?.[parentCriteria] || ''}
                          onChange={(e) => handleWeightageChange(parentCriteria, parentCriteria, e)}
                        />
                      ) : weightageValues[parentCriteria]?.[parentCriteria]}</td>
                      <td>{categories[Object.keys(categories)[0]]?.Project_Deviation || ''}</td>
                      <td>{categories[Object.keys(categories)[0]]?.Avg_Penalty || ''}</td>
                      <td>{categories[Object.keys(categories)[0]]?.Penalty || ''}</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="penalty-box">
                <div className="penalty-row">
                  <div className="penalty-label">Total Penalty</div>
                  <div className="penalty-value">
                    {parseFloat(categories[Object.keys(categories)[0]]?.Total_Penalty || 0).toFixed(2)}
                  </div>
                </div>
                <div className="penalty-row">
                  <div className="penalty-label">Penalty Value</div>
                  <div className="penalty-value">
                    {parseFloat(categories[Object.keys(categories)[0]]?.Penalty_value || 0).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="button-container">
        <button className="update-button" onClick={handleUpdate}>
          {isEditing ? 'Save' : 'Update'}
        </button>
        <button className="evaluate-button" onClick={handleEvaluate}>
          Evaluate
        </button>
      </div>

      <Confirmationmodal
        isOpen={isModalOpen}
        message={confirmationMessage}
        isError={isError}
        onClose={() => setIsModalOpen(false)} // Close the modal when clicking the close button
      />

      {isServiceModalOpen && (
        <div className="service-modal">
          <button className="close-modal" onClick={handleCloseServiceModal}>Close</button>
        </div>
      )}
    </div>
  );
};

export default Summary;

import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import pwc_top_logo from "../assets/pwc_top_logo.png";
import admin from "../assets/admin.png";
import logout from "../assets/logout.png";
import criteriaIcon from "../assets/criteria.png";
import reportIcon from "../assets/report.png";
import statisticsIcon from "../assets/statistics.png";
import summaryIcon from "../assets/summary.png";
import historyIcon from "../assets/history.png";
import CONFIG from "../config.js"

const Layout = () => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    const storedUsername = localStorage.getItem("username");
    if (loggedInUser === "true" && storedUsername) {
      setAuthenticated(true);
      setUsername(storedUsername);
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    setShowModal(true); // Show confirmation modal
  };

  const confirmLogout = async () => {
    try {
      const response = await fetch(`${CONFIG.API_BASE_URL}api/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username }),
      });

      const data = await response.json();
      if (data.success) {
        localStorage.removeItem("authenticated");
        localStorage.removeItem("username");
        localStorage.removeItem("criteriaName");
        localStorage.removeItem("description");
        localStorage.removeItem("milestones");
        localStorage.removeItem("bau");
        localStorage.removeItem("summaryData");
        setAuthenticated(false);
        navigate("/login");
      } else {
        console.error("Failed to logout: " + data.message);
      }
    } catch (error) {
      console.error("Error logging out", error);
    } finally {
      setShowModal(false); // Hide modal after logout
    }
  };

  const cancelLogout = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="container">
        <div className="sidebar">
          <button onClick={() => handleNavigation("/criteria")}>
            <img src={criteriaIcon} alt="Criteria" className="sidebar-icon" />
            Criteria
          </button>
          <button onClick={() => handleNavigation("/report")}>
            <img src={reportIcon} alt="Report" className="sidebar-icon" />
            Report
          </button>
          <button onClick={() => handleNavigation("/statistics")}>
            <img src={statisticsIcon} alt="Statistics" className="sidebar-icon" />
            Statistics
          </button>
          <button onClick={() => handleNavigation("/summary")}>
            <img src={summaryIcon} alt="Summary" className="sidebar-icon" />
            Summary
          </button>
          <button onClick={() => handleNavigation("/history")}>
            <img src={historyIcon} alt="History" className="sidebar-icon" />
            History
          </button>
        </div>

        <div className="main-content">
          <div className="header">
            <div className="header-left">
              <img src={pwc_top_logo} alt="Logo" className="logo" />
              <div className="title">Service Level Agreement Management Tool</div>
            </div>
            <div className="header-right">
              <div className="admin-info">
                <img src={admin} alt="Admin Icon" className="admin-icon" />
                <div className="welcome-text">
                  Welcome {username}, <span>&lt;ABC&gt; Bank</span>
                </div>
                <div className="logout" onClick={handleLogout}>
                  <span>Logout</span>
                  <img src={logout} alt="Logout Icon" className="logout-icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <Outlet /> {/* Render nested routes here */}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Unsaved changes will be lost. Do you still want to continue?</p>
            <button onClick={confirmLogout}>Yes</button>
            <button onClick={cancelLogout}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;

import React, { useState, useEffect} from 'react';
import Modal from 'react-modal';
import './UpdateCriteriaPM.css';
import updatearrow from "../../assets/updatearrow.svg";
import cross from "../../assets/cross.svg";
import editsquare from "../../assets/Edit square.svg";
import DeletePopUp from './DeletePopUp';
import EditPopUp from './EditPopUp';
import config from '../../config';

const UpdateCriteriaPM = ({ isOpen, onRequestClose, onUpdateMilestones, criteriaId }) => {
    const [criteriaName, setCriteriaName] = useState('');
    const [fetchedMilestones, setFetchedMilestones] = useState([]);
    const [description, setDescription] = useState('');
    const [milestones, setMilestones] = useState([]);
    const [newCriterion, setNewCriterion] = useState('');
    const [slaValue, setSlaValue] = useState('');
    const [priority, setPriority] = useState('');
    const [SLA_type, setSLA_type] = useState('');
    const [actualServiceLevel, setActualServiceLevel] = useState('');
    const [remarks, setRemarks] = useState('');
    const [isSlaModalOpen, setIsSlaModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editData, setEditData] = useState(null); // Changed from editIndex to editData
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [error, setError] = useState('');
    const storedUsername = localStorage.getItem('username') || '';

    useEffect(() => {
        if (isOpen && storedUsername && criteriaId) {
            fetchCriteriaContent(storedUsername, criteriaId, criteriaName);
        }
    }, [isOpen, storedUsername, criteriaId, criteriaName]);

    const fetchCriteriaContent = (username, criteriaId, criteriaName) => {
        //alert(criteriaName);
        fetch(`${config.API_BASE_URL}api/criteria_content`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                criteria_id: criteriaId,
                criteriaName: criteriaName
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.data && data.data.length > 0) {
                const criteria = data.data[0];
                setCriteriaName(criteria.criteria_name);
                setDescription(criteria.criteria_desc);

                if (criteria.Service_Category) {
                    const fetchedMilestones = data.data.map(item => ({
                        id: criteriaId,
                        name: item.Service_Category,
                        sla: item.SLA_Pcnt,
                        priority: item.Priority,
                        SLA_type: item.SLA_type,
                        actualServiceLevel: item.Actual_Service_Level_percent,
                        remarks: item.remarks
                    }));
                    setFetchedMilestones(fetchedMilestones);
                    //mergeMilestones (fetchedMilestones);
                }
                else{
                setFetchedMilestones([]);
                //mergeMilestones (fetchedMilestones);
                }
            } else {
                console.log('No data found');
            }
        })
        .catch(error => {
            console.error('Error fetching criteria content:', error);
        });
    };

    useEffect(() => {
        const storedMilestones = JSON.parse(localStorage.getItem('milestones')) || []; // Fallback to an empty array
        const mergedMilestones = [...storedMilestones];

        // Check if fetchedMilestones exists or storedMilestones is empty
        if ((Array.isArray(storedMilestones) && storedMilestones.length === 0)) {
            const storedNames = storedMilestones.map(milestone => milestone.name);

            if (fetchedMilestones) {
                fetchedMilestones.forEach(fetchedMilestone => {
                    if (!storedNames.includes(fetchedMilestone.name)) {
                        mergedMilestones.push(fetchedMilestone);
                    }
                });
            }
        }

        setMilestones(mergedMilestones);
    }, [fetchedMilestones]);
    
    const handleUpdate = () => {
        if (!criteriaName.trim()) {
            setError('Criteria Name is required');
            return;
        }

        fetch(`${config.API_BASE_URL}api/add_criteria`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: storedUsername,
                criteriaName: criteriaName,
                criteriaDesc: description,
                criteriaId: criteriaId,
                milestones: milestones
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log('Criteria added successfully:', data.message);
            } else {
                console.error('Failed to add criteria:', data.message);
            }
            onRequestClose();
        })
        .catch(error => {
            console.error('Error adding criteria:', error);
        });
    };

    const openDeleteModal = (index) => {
        setDeleteIndex(index);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => setIsDeleteModalOpen(false);

    const handleDeleteConfirm = () => {
        if (deleteIndex !== null) {
            const updatedMilestones = milestones.filter((_, index) => index !== deleteIndex);
            setMilestones(updatedMilestones);
            onUpdateMilestones(updatedMilestones);
        }
        closeDeleteModal();
    };

    const openEditModal = (index) => {
        setEditData(milestones[index]); // Set the entire milestone data
        setEditIndex(index); // Store the index
        setIsEditModalOpen(true);
    };
    
    const handleSaveEdit = (editedMilestone) => {
        const updatedMilestones = [...milestones]; // Create a copy of the current milestones
        updatedMilestones[editIndex] = editedMilestone; // Update the milestone at the specific index
        setMilestones(updatedMilestones);
        onUpdateMilestones(updatedMilestones);
        closeEditModal();
    };    

    const closeEditModal = () => setIsEditModalOpen(false);

    const openSlaModal = () => setIsSlaModalOpen(true);
    const closeSlaModal = () => setIsSlaModalOpen(false);

    const handleAddCriterion = () => {
        openSlaModal();
    };
    const formatSlaValue = (value) => {
        // Remove any characters that are not digits or a decimal point
        let cleanedValue = value.replace(/[^0-9.]/g, '');

        // Handle multiple decimal points
        const parts = cleanedValue.split('.');
        if (parts.length > 2) {
            cleanedValue = `${parts[0]}.${parts.slice(1).join('')}`;
        }

        // Limit decimal places to two
        if (parts[1] && parts[1].length > 2) {
            cleanedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }

        return cleanedValue;
    };
    const handleSlaValueChange = (e) => {
        const formattedValue = formatSlaValue(e.target.value);
        setSlaValue(formattedValue);
    };
    const handleSubmitCriterion = () => {
        setMilestones([...milestones, { id: criteriaId, name: newCriterion, sla: slaValue, priority, SLA_type, actualServiceLevel, remarks }]);
        closeSlaModal();
        setNewCriterion('');
        setSlaValue('');
        setPriority('');
        setSLA_type('');
        setActualServiceLevel('');
        setRemarks('');
    };

    const handleUpdateMilestones = () => {
        localStorage.setItem('criteriaName', criteriaName);
        localStorage.setItem('description', description);
        localStorage.setItem('milestones', JSON.stringify(milestones));
        onUpdateMilestones(milestones);
        onRequestClose();
    };

    useEffect(() => {
        // Adjust tbody width based on scrollbar presence
        const tbody = document.querySelector('.miles-table tbody');
        if (tbody) {
            const hasScrollbar = tbody.scrollHeight > tbody.clientHeight;
            tbody.style.width = hasScrollbar ? 'calc(100% + 7px)' : '100%';
        }
    }, [milestones]); // Recalculate when milestones change


    return (
        <Modal isOpen={isOpen} className="update_modal-content" overlayClassName="update_modal-overlay">
            <div className="modal-header">
                <div className="head-left"></div>
                <button type="button" className="update-criteria-button">UPDATE CRITERIA</button>
                <div className="head-right">
                    <button onClick={onRequestClose} className="close-button">&times;</button>
                </div>
            </div>
            <form className="criteria-form">
                <label>
                    Criteria Name<span className="required-field">*</span>
                    <input 
                        type="text" 
                        name="criteriaName" 
                        value={criteriaName} 
                        onChange={(e) => setCriteriaName(e.target.value)}
                    />
                    {error && <div className="error-message">{error}</div>}
                </label>
                <label>
                    Description
                    <textarea 
                        name="description" 
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </label>
            </form>
            <div className="miles-table-container">
            <table className="miles-table">
            <thead>
                        <tr>
                            <td colSpan="4" className="mil-header">
                                <div className="add-serv-container">
                                    <span className="shift-up">Project Milestones: </span>
                                    <div className="add-serv">
                                        <input
                                            type="text"
                                            className="add-serv-input"
                                            placeholder="Add Parameter"
                                            value={newCriterion}
                                            onChange={(e) => setNewCriterion(e.target.value)}
                                        />
                                    </div>
                                    <img src={updatearrow} className="uparw-icon" alt="arrow" onClick={handleAddCriterion} />
                                </div>
                            </td>
                        </tr>
                    <tr>
                        <th width='248px'>PROJECT MILESTONES</th>
                        <th width='51px'>SLA</th>
                        <th width='70px'>PRIORITY</th>
                        <th width="100px">SLA TYPE</th>
                    </tr>
                </thead>
                <div className="miles-table-wrapper">
                <tbody>
                    {milestones.map((milestone, index) => (
                        <tr key={index}>
                            <td width='248px' title={milestone.name}>
                            <div className="cell-container" >
                                <div className="cell-content">
                                    {milestone.name}
                                </div>
                                <div className="icons-container">
                                    <img src={editsquare} className="edts" onClick={() => openEditModal(index)} alt="edit" />
                                    <img src={cross} className="edts" onClick={() => openDeleteModal(index)} alt="delete" />
                                </div>
                            </div>
                            </td>
                            <td width='51px' title={milestone.sla}>
                                    {milestone.sla}
                            </td>
                            <td width='70px' title={milestone.priority}>
                                    {milestone.priority}
                            </td>
                            <td width='100px' title={milestone.SLA_type}>
                                    {milestone.SLA_type}
                            </td>
                        </tr>
                    ))}
                </tbody>
                </div>
            </table>
            </div>

            <button type="button" className="update-button" onClick={handleUpdateMilestones}>Update</button>
            <Modal isOpen={isSlaModalOpen} onRequestClose={closeSlaModal} className="edit-modal-content-pm" overlayClassName="edit-modal-overlay-pm">
                <h2>Add SLA , Priority and SLA Type</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleSubmitCriterion(); }}>
                    <label>
                        SLA Value:
                        <input type="text" value={slaValue} onChange={handleSlaValueChange} 
                             />
                    </label>
                    <label>
                        Priority:
                        <select value={priority} onChange={(e) => setPriority(e.target.value)}>
                            <option value="">Select</option>
                            <option value="HIGH">HIGH</option>
                            <option value="NORMAL">NORMAL</option>
                            <option value="LOW">LOW</option>
                        </select>
                    </label>
                    <label>
                        SLA Type:
                        <select value={SLA_type} onChange={(e) => setSLA_type(e.target.value)}>
                            <option value="">Select</option>
                            <option value="SLA Adherence">SLA Adherence</option>
                            <option value="SLA Deviation">SLA Deviation</option>
                        </select>
                    </label>
                    <button type="button" onClick={handleSubmitCriterion}>Submit</button>
                </form>
            </Modal>
            <DeletePopUp isOpen={isDeleteModalOpen} onRequestClose={closeDeleteModal} onDeleteConfirm={handleDeleteConfirm} />
            <EditPopUp
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                milestoneData={editData}
                onSave={handleSaveEdit}
            />
        </Modal>
    );
};

export default UpdateCriteriaPM;
